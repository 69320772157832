import React, { useEffect, useState } from "react";
import "./ContributorEdit.scss";
import firebase, { firebaseApp, firestoreDB } from "../../../firebase";
import { useNavigate, useParams } from "react-router-dom";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import TextField from "../../../components/TextField/TextField";
import { Contributor, Service, STATUS, Whitelist } from "../../../interfaces/interfaces";
import { COMMON_STATUSES, CONTRIBUTOR_TYPES } from "../../../constants";
import DropDown from "../../../components/DropDown/DropDown";
import { BlueButton } from "../../../components/Button/Button";
import Dropzone from "../../../components/Dropzone/Dropzone";
import { Tooltip } from "@mui/material";
import { getFileShown } from "../../../util/fileUtil";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import trashSVG from "../../../assets/trash.svg";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const ContributorEdit = (props: any) => {
  let params = useParams();
  const navigate = useNavigate();
  if (!props.isLogin) {
    navigate('/login');
  }
  const [contributor, setContributor] = useState<Contributor>({
    files: [],
    contributorId: '',
    serviceIds: [],
    whitelistIds: [],
    phone: '',
    status: STATUS.ACTIVE,
    name: '',
    type: [],
    email: '',
    notification: ''
  });

  const [services, setServices] = useState<Service[]>([]);
  const [whitelist, setWhitelist] = useState<Whitelist[]>([]);

  const fetchContributor = () => {
    const q = query(collection(firestoreDB, "contributor"), where("contributorId",  "==", params.contributorId) );
    const unsubscribe = onSnapshot(q, (querySnapshot: any) => {
      const res: Array<object> = [];
      
      querySnapshot.forEach((doc: any) => {
        res.push(doc.data());
      });

      const data: Contributor = res[0] as Contributor;
      if (data) {
        setContributor(data);
      }
    });
  };

  const fetchServices = () => {
    const q = query(collection(firestoreDB, "services"));
    const unsubscribe = onSnapshot(q, (querySnapshot: any) => {
      const res: Array<Service> = [];
      
      querySnapshot.forEach((doc: any) => {
        res.push(doc.data());
      });

      setServices(res);
    });
  };

  const fetchWhitelist = () => {
    const q = query(collection(firestoreDB, "whitelist"));
    const unsubscribe = onSnapshot(q, (querySnapshot: any) => {
      const res: Array<Whitelist> = [];
      
      querySnapshot.forEach((doc: any) => {
        res.push(doc.data());
      });

      setWhitelist(res);
    });
  };

  const save = async(event: any) => {
    event.preventDefault();
    if (contributor && params.contributorId) {
      contributor.contributorId = params.contributorId;
    }
    await firebase.updateContributor(contributor as any);
    navigate('/contributors');
  };

  const handleChange = (field: any) => (value: any) => {
    const data = contributor as any;
    setContributor({ ...data, [field]: value });
  };

  const onDataChange = (file: any) => {
    setContributor((prevState: any) => {
      const files = prevState.files || [];
      files.push(file);
      return { ...prevState, files };
    });
  };

  const deleteFile = (file: any) => {
    const files = contributor.files;
    var index = files.indexOf(file);
    if (index !== -1) {
      files.splice(index, 1);
    }
    setContributor({ ...contributor, files });
  };

  useEffect(() => {
    fetchContributor();
    fetchServices();
    fetchWhitelist();
  }, []);

  return (
    <div className="ui form" style={{padding: '10px 24px'}}>
      <a className="backBtn" onClick={() => navigate(-1)}>
          <ArrowBackIosIcon />
          Back
      </a>
      <h3>{contributor?.createdDate ? 'Edit' : 'New'} Contributor</h3>
      <form id="form" onSubmit={save}>
        <h4>Contributor details</h4>

        <div className="two fields" style={{ marginTop: "24px" }}>
          <TextField
            label="Name *"
            onChange={handleChange("name")}
            value={contributor?.name}
            required={true}
          />

          <TextField
            label="E-mail"
            onChange={handleChange("email")}
            value={contributor?.email}
            type="email"
          />
        </div>

        <div className="two fields" style={{ marginTop: "24px" }}>
          <TextField
              label="Phone Number"
              onChange={handleChange("phone")}
              value={contributor?.phone}
            />
        </div>

        <div className="two fields" style={{ marginTop: "24px" }}>
          <TextField
            label="Notification"
            onChange={handleChange("notification")}
            value={contributor?.notification}
          />

          <DropDown
            label="Status"
            options={COMMON_STATUSES.map((e) => {
              return { key: e, value: e, text: e };
            })}
            onChange={handleChange("status")}
            value={contributor?.status}
          />
        </div>

        <div className="two fields" style={{ marginTop: "24px" }}>
          <DropDown
            label="Services"
            options={services.map((e) => {
              return { key: e.serviceId, value: e.serviceId, text: e.type };
            })}
            onChange={handleChange("serviceIds")}
            value={contributor?.serviceIds}
            multiple={true}
          />

          <DropDown
            label="Whitelist"
            options={whitelist.map((e) => {
              return { key: e.whitelistId, value: e.whitelistId, text: `${e.companyName} - ${e.category}` };
            })}
            onChange={handleChange("whitelistIds")}
            value={contributor?.whitelistIds}
            multiple={true}
          />
        </div>

        <div className="two fields" style={{ marginTop: "24px" }}>
          <DropDown
            label="Type"
            options={CONTRIBUTOR_TYPES.map((e) => {
              return { key: e, value: e, text: e };
            })}
            onChange={handleChange("type")}
            value={contributor?.type}
            multiple={true}
          />
        </div>

        <Dropzone
          label='Files'
          onDataChange={onDataChange}
        />
        {contributor.files && contributor.files.length > 0 ? (
          <div className="field" style={{ marginTop: "24px" }}>
            <label>Files</label>
            {contributor.files.map((e: any) => {
              return (
                <div
                  key={e.name}
                  style={{ marginBottom: "5px", display: "flex" }}
                >
                  <Tooltip title={`Delete File ${e.name}`}>
                    <img
                      src={trashSVG}
                      onClick={() => deleteFile(e)}
                      style={{
                        marginLeft: "0",
                        marginRight: "5px",
                        width: "16px",
                        verticalAlign: "middle",
                      }}
                    />
                  </Tooltip>
                  <span style={{ verticalAlign: "middle" }}>{e.name}</span>
                  <OpenInNewIcon
                    style={{ verticalAlign: "middle", marginLeft: "5px" }}
                    onClick={() => window.open(getFileShown(e))}
                  />
                </div>
              );
            })}
          </div>
        ) : null}

        <button 
          className="ui primary button floated"
          style={{ marginBottom: "20px" }}
          type="submit" 
          form="form" 
          value="Save">Save</button>
      </form>
    </div>
  );
};

export default ContributorEdit;
