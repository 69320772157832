import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./CandidatePage.scss";
import { BlueButton } from "../../components/Button/Button";
import TextField from "../../components/TextField/TextField";
import RadioButtons from "../../components/RadioButtons/RadioButtons";
import firebase, { firestoreDB } from "../../firebase";
import Dropzone from "../../components/Dropzone/Dropzone";
import _ from "lodash";
import trashSVG from "../../assets/trash.svg";
import { Box, CircularProgress, Tooltip } from "@mui/material";
import SuccessModal from "../../components/SuccessModal/SuccessModal";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { getFileShown } from "../../util/fileUtil";
import { language } from "../../language";
import { MARITALSTATUS, WORK_PERMITS, GENDER, COMMON_STATUSES, ROLES } from "../../constants";
import DropDown from "../../components/DropDown/DropDown";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { Contributor } from "../../interfaces/interfaces";

export interface ICandidate extends Record<string, any> {
  name: string;
  id: string;
  title: string;
  phase: number;
  residencePermit: string;
  nationality: string;
  start: string;
  education: string;
  languageDE: string;
  languageEN: string;
  interview: string;
  payRate: string;
  chargeRate: string;
  active: boolean;
}

export interface IDataType extends Record<string, any> {
  name: string;
  base64: string;
  type: string;
}

const CandidatePage = ({ lang, isLogin, userData }: any) => {
  let params = useParams();
  const [data, setData] = useState<ICandidate>({
    residencePermit: "",
    id: "",
    nationality: "",
    start: "",
    education: "",
    languageDE: "",
    languageEN: "",
    title: "",
    interview: "",
    payRate: "",
    chargeRate: "",
    name: "",
    phase: 1,
    active: true,
    contributorId: userData.role ==='contributor' ? userData.contributor.contributorId: null
  });

  const [contributors, setContributors] = useState<Contributor[]>([]);
  const [loading, setLoading] = useState(Boolean);

  // phase
  const [pageLocked, setPageLocked] = useState(true);
  // modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleUpdate = async () => {
    if (params.candidateID) {
      const res: any = await firebase.updateCandidate(params.candidateID, data);
      const candData: ICandidate = res as ICandidate;
      setData({ ...candData, id: params.candidateID });
    } else {
      console.error("no id yet");
    }
    handleOpen();
  };

  const handleChange = (field: any) => (value: any) => {
    const f = _.cloneDeep(data);
    setData({ ...f, [field]: value });
  };

  const fetch = async (candidateID: string) => {
    setLoading(true);
    const res: any = await firebase.getCandidate(candidateID);
    const candData: ICandidate = res as ICandidate;
    setData({ ...data, ...candData, id: candidateID });
    setLoading(false);
    if (candData.phase === 2) {
      setPageLocked(false);
    }
  };

  const onDataChange = (file: any) => {
    setData((prevState) => {
      const files = prevState.files || [];
      files.push(file);
      return { ...prevState, files };
    });
  };

  const fetchContributors = () => {
    const q = query(collection(firestoreDB, "contributor"), where("status",  "==", "Active"));
    const unsubscribe = onSnapshot(q, (querySnapshot: any) => {
      const res: Array<object> = [];
      
      querySnapshot.forEach((doc: any) => {
        res.push(doc.data());
      });
      setContributors(res as Contributor[]);
    });
  };

  useEffect(() => {
    fetch(params.candidateID!);
    fetchContributors()
  }, []);

  const deleteFile = (file: IDataType) => {
    const files = data.files;
    var index = files.indexOf(file);
    if (index !== -1) {
      files.splice(index, 1);
    }
    setData({ ...data, files });
  };

  const getPage1 = () => {
    return (
      <div className="ui form">
        <Dropzone
          label={language["dropCV"][lang]}
          onDataChange={onDataChange}
        />
        {data.files && data.files.length > 0 ? (
          <div className="field" style={{ marginTop: "24px" }}>
            <label>Files</label>
            {data.files.map((e: IDataType) => {
              return (
                <div
                  key={e.name}
                  style={{ marginBottom: "5px", display: "flex" }}
                >
                  <Tooltip title={`Delete File ${e.name}`}>
                    <img
                      src={trashSVG}
                      onClick={() => deleteFile(e)}
                      style={{
                        marginLeft: "0",
                        marginRight: "5px",
                        width: "16px",
                        verticalAlign: "middle",
                      }}
                    />
                  </Tooltip>
                  <span style={{ verticalAlign: "middle" }}>{e.name}</span>
                  <OpenInNewIcon
                    style={{ verticalAlign: "middle", marginLeft: "5px" }}
                    onClick={() => window.open(getFileShown(e))}
                  />
                </div>
              );
            })}
          </div>
        ) : null}
        
        <div className="two fields" style={{ marginTop: "24px" }}>
          <TextField
            label="Name"
            onChange={handleChange("name")}
            value={data.name}
          />
        </div>

        <div className="two fields" style={{ marginTop: "24px" }}>
          <DropDown
            label={language["residencePermit"][lang]}
            options={WORK_PERMITS.map((e) => {
              return { key: e, value: e, text: e };
            })}
            onChange={handleChange("residencePermit")}
            value={(data && data.residencePermit) || ""}
          />
          <TextField
            label={language["nationality"][lang]}
            onChange={handleChange("nationality")}
            value={data.nationality}
          />
        </div>

        <div className="two fields" style={{ marginTop: "24px" }}>
          <TextField
            label={language["possibleStartDate"][lang]}
            onChange={handleChange("start")}
            value={data.start}
          />
          <TextField
            label={language["interviewAvail"][lang]}
            onChange={handleChange("interview")}
            value={data.interview}
            rows={3}
          />

        </div>

        <div className="field">
          <label className=".ui.form .field>label">
            {language["candidateLanguage"][lang]}
          </label>
          <div className="inline two fields">
            <div className="field">
              <RadioButtons
                label={language["languageDE"][lang]}
                options={["Native", "Fluent", "Business Fluent"]}
                onChange={handleChange("languageDE")}
                checked={(data && data.languageDE) || ""}
              />
            </div>
            <div className="field">
              <RadioButtons
                label={language["languageEN"][lang]}
                options={["Native", "Fluent", "Business Fluent"]}
                onChange={handleChange("languageEN")}
                checked={(data && data.languageEN) || ""}
                fieldStyle={{ paddingLeft: "0.5em" }}
              />
            </div>
          </div>
        </div>

        {/* Fields only for logged in user */}
        {isLogin ?
          (
            <div>
              <div className="two fields" style={{ marginTop: "24px" }}>
                <TextField
                  label={language["newsletterTitle"][lang]}
                  onChange={handleChange("title")}
                  value={data.title}
                />
                <TextField
                  label={language["educationalAttainment"][lang]}
                  onChange={handleChange("education")}
                  value={data.education}
                />
              </div>

              <div className="two fields" style={{ marginTop: "24px" }}>
                <TextField
                  label="Pay Rate (without margin)"
                  onChange={handleChange("payRate")}
                  value={data.payRate}
                />
                <TextField
                  label="Charge Rate (with margin)"
                  onChange={handleChange("chargeRate")}
                  value={data.chargeRate}
                />
              </div>

              <div className="two fields" style={{ marginTop: "24px" }}>
              {userData?.role === ROLES.OPERATOR ?
                <DropDown
                    label={language["contributor"][lang]}
                    options={contributors.map((e) => {
                      return { key: e.contributorId, value: e.contributorId, text: e.name };
                    })}
                    onChange={handleChange("contributorId")}
                    value={(data && data.contributorId) || ""}
                  />
              :null}
                  <DropDown
                    label="Status"
                    options={COMMON_STATUSES.map((e) => {
                      return { key: e, value: e, text: e };
                    })}
                    onChange={handleChange("status")}
                    value={(data && data.status) || ""}
                  />
              </div>
            </div>

          ) : null}

      </div>
    );
  };

  const getPage2 = () => {
    return (
      <div className="ui form">
        <div className="two fields" style={{ marginTop: "24px" }}>
          <TextField
            label={language["birthdate"][lang]}
            onChange={handleChange("birthday")}
            value={data.birthday}
          />
          <DropDown
            label={language["gender"][lang]}
            options={GENDER.map((e) => {
              return { key: e, value: e, text: language[e + "GenderOption"][lang] };
            })}
            onChange={handleChange("gender")}
            value={data.gender}
          />
        </div>

        <div className="two fields" style={{ marginTop: "24px" }}>
          <TextField
            label={language["childrenU12"][lang]}
            onChange={handleChange("childrenU12")}
            value={data.childrenU12}
          />
          <TextField
            label={language["childrenO12"][lang]}
            onChange={handleChange("childrenO12")}
            value={data.childrenO12}
          />
        </div>
        <div className="two fields" style={{ marginTop: "24px" }}>
          <DropDown
            label={language["marital_status"][lang]}
            options={MARITALSTATUS.map((e) => {
              return { key: e, value: e, text: language["marital_status" + e.replace(/ /g, '')][lang] };
            })}
            onChange={handleChange("status")}
            value={(data && data.status) || ""}
          />
          <TextField
            label={language["address"][lang]}
            onChange={handleChange("address")}
            value={data.address}
            rows={3}
          />
        </div>
      </div>
    );
  };

  const getPage = () => {
    if (pageLocked) {
      return getPage1();
    } else {
      return (
        <>
          {getPage1()} {getPage2()}
        </>
      );
    }
  };

  return (
    <div className="form-page">
      <div style={{ width: "100%", marginLeft: "160px", marginRight: "160px" }}>
        <SuccessModal open={open} handleClose={handleClose} language={lang} />

        <h2>Personal Information</h2>
        <hr />
        {/*//*/}
        {loading ?
        <Box sx={{ display: 'flex' }}>
          <CircularProgress sx={{margin: '24px auto'}} />
        </Box>
        :
        getPage()}
        <div
          style={{
            width: "100%",
            justifyContent: "flex-end",
            display: "flex",
            marginBottom: "30px",
          }}
        >
          <BlueButton
            label={language["save"][lang]}
            onClick={() => handleUpdate()}
            style={{ marginRight: "0", marginLeft: "20px" }}
          />
        </div>
      </div>
    </div>
  );
};

export default CandidatePage;
