
export const language: any = {
  dropCV: {
    en: "Click to upload or drag and drop your CV files",
    de: "CV Files hochladen",
  },
  contributor: {
    de: "Contributor",
    en: "Contributor",
  },
  positionDescription: {
    de: "Job Titel *",
    en: "Job Title *",
  },
  seniority: {
    de: "Seniorität",
    en: "Seniority",
  },
  candidateLanguage: {
    de: "Sprache",
    en: "Language",
  },
  start: {
    de: "Möglicher Einsatzbeginn",
    en: "Desired Start Date",
  },
  end: {
    de: "Einsatzdauer",
    en: "Duration",
  },
  pensum: {
    de: "Pensum",
    en: "Workload",
  },
  chargeRate: {
    de: "Charge Rate (CHF)",
    en: "Charge Rate (CHF)",
  },
  tasks: {
    de: "Aufgaben",
    en: "Tasks",
  },
  reqs: {
    de: "Anforderungen",
    en: "Requirements",
  },
  location: {
    de: "Arbeitsort",
    en: "Work Location",
  },
  remarks: {
    de: "Bemerkungen",
    en: "Remarks",
  },
  seniorityProfessional: {
    de: "Professional (ab 3 Jahre)",
    en: "Professional (3 years+)",
  },
  senioritySenior: {
    de: "Senior (ab 5 Jahre)",
    en: "Senior (5 years+)",
  },
  seniorityExpert: {
    de: "Expert (ab 8 Jahre)",
    en: "Expert (8 years+)",
  },
  senioritySeniorExpert: {
    de: "Senior Expert (ab 12 Jahre)",
    en: "Senior Expert (12 years+)",
  },
  other: {
    de: "Andere:",
    en: "Other:",
  },
  m1: {
    de: "1 Monat",
    en: "1 Month",
  },
  m3: {
    de: "3 Monate",
    en: "3 Months",
  },
  m6: {
    de: "6 Monate",
    en: "6 Months",
  },
  m12: {
    de: "12 Monate",
    en: "12 Months",
  },
  y2: {
    de: "2 Jahre",
    en: "2 Years",
  },
  locationOption1: {
    de: "100% vor Ort",
    en: "100% on site",
  },
  locationOption2: {
    de: "1-2 Tage Remote-Arbeit",
    en: "1-2 days remote",
  },
  locationOption3: {
    de: "3-4 Tage Remote-Arbeit",
    en: "3-4 days remote",
  },
  locationOption4: {
    de: "100% CH Home Office",
    en: "100% CH Home Office",
  },

  footerLeft: {
    de:
      "Suchen Sie eine Fachkraft ein ganzes Team oder einfach nur temporäre Untestützung für Ihr Team? \n Dann sind Sie bei mir genau richtig." +
      "\n" +
      "Gerne finde ich für Sie DIE Lösung.",
    en:
      "Suchen Sie eine Fachkraft ein ganzes Team oder einfach nur temporäre Untestützung für Ihr Team? \n Dann sind Sie bei mir genau richtig." +
      "\n" +
      "Gerne finde ich für Sie DIE Lösung.",
  },

  footerRightName: "Armin Freund",

  footerRight: "+ 41 76 276 32 72 \n armin.freund@esg-workforce.ch",

  footerRightAddressName: "ESG Workforce AG",

  footerRightAddress: "Vulkanstrasse 106 \n 8048 Zürich \n +41 43 321 99 82 \n info@esg-workforce.ch",

  company: {
    de: "Firma *",
    en: "Company *",
  },
  contact: {
    de: "Name *",
    en: "Name *",
  },
  contactPhone: {
    de: "Telefon (*)",
    en: "Phone (*)",
  },
  contactMail: {
    de: "Email (*)",
    en: "Email (*)",
  },
  languageFluent: {
    de: "Fliessend",
    en: "Fluent",
  },
  languageAdvanced: {
    de: "Fortgeschritten",
    en: "Advanced",
  },
  languageDE: {
    de: "Deutsch",
    en: "German",
  },
  languageEN: {
    de: "Englisch",
    en: "English",
  },
  contactDetails: {
    de: "Kontakt",
    en: "Contact Details",
  },
  asap: {
    de: "So bald wie möglich",
    en: "As soon as possible",
  },
  saveText1: {
    de: "Wir werden so bald wie möglich auf Sie zurückkommen.",
    en: "We will get back to you as soon as possible.",
  },
  saveText2: {
    de: "Bitte kontaktieren Sie uns falls Sie in der Zwischenzeit Fragen haben.",
    en: "Please do not hesitate to get in touch with us in the meantime if you have any questions.",
  },
  saveTextHeader: {
    de: "Vielen Dank für Ihre Anfrage.",
    en: "Thank you, your request has been submitted.",
  },
  save: {
    de: "Speichern",
    en: "Save",
  },
  submit: {
    de: "Anfrage Stellen",
    en: "Submit",
  },
  residencePermit: {
    de: "Aufenthaltsgenehmigung",
    en: "Work Permit",
  },
  nationality: {
    de: "Nationalität",
    en: "Nationality",
  },
  possibleStartDate: {
    de: "Mögliches Anfangs Datum",
    en: "Possible Start Date",
  },
  educationalAttainment: {
    de: "Höchster Bildungsabschluss",
    en: "Highest Educational Attainment",
  },
  newsletterTitle: {
    de: "Newsletter Job Titel",
    en: "Newsletter Job Title",
  },
  interviewAvail: {
    de: "Mögliche Interview Daten",
    en: "Interview Availabilities",
  },
  birthdate: {
    de: "Geburtsdatum",
    en: "Date of Birth",
  },
  gender: {
    de: "Geschlecht",
    en: "Gender",
  },
  MaleGenderOption: {
    de: "Männlich",
    en: "Male",
  },
  FemaleGenderOption: {
    de: "Weiblich",
    en: "Female",
  },
  childrenU12: {
    de: "Kinder unter 12 Jahre",
    en: "Children under 12 years",
  },
  childrenO12: {
    de: "Kinder über 12 Jahre",
    en: "Children above 12 years",
  },
  // Marital_status
  marital_status: {
    de: "Zivilstand",
    en: "Marital Status",
  },
  marital_statusMarried: {
    de: "Verheiratet",
    en: "Married",
  },
  marital_statusSingle: {
    de: "Ledig",
    en: "Single",
  },
  marital_statusDivorced: {
    de: "Geschieden",
    en: "Divorced",
  },
  marital_statusWidowed: {
    de: "Verwitwet",
    en: "Widowed",
  },
  marital_statusRegisteredPartnership: {
    de: "Eingetragene Partnerschaft",
    en: "Registered Partnership",
  },

  address: {
    de: "Adresse",
    en: "Residential Address",
  },
  languageFields: {
    en: {
      Fluent: "Fluent",
      Advanced: "Advanced",
    },
    de: {
      Fluent: "Flüssig",
      Advanced: "Fortgeschritten",
    },
  },
  // Table fields:  
  tableFields: {
    en: {
      Candidate: "Candidate Name",
      Title: "Job Title",
      Start: "Possible Start date",
      Rate: "Rate (CHF)",
      Available: "Available for interviews",
      Files: "Files"
    },
    de: {
      Candidate: "Namen des Kandidaten",
      Title: "Jobtitle",
      Start: "Mögliches Startdatum",
      Rate: "Rate (CHF)",
      Available: "Verfügbarkeit für Interviews",
      Files: "Dateien"
    },
  },
};

// interface languageType {
//     [id:string]: object|string;
// }
export type LanguageType = keyof typeof language;
