import React, { useEffect, useState } from "react";
import "./WhitelistEdit.scss";
import firebase, { firebaseApp, firestoreDB } from "../../../firebase";
import { useNavigate, useParams } from "react-router-dom";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import TextField from "../../../components/TextField/TextField";
import { Whitelist } from "../../../interfaces/interfaces";

const WhitelistEdit = (props: any) => {
  let params = useParams();
  const navigate = useNavigate();
  if (!props.isLogin) {
    navigate('/login');
  }
  const [whitelist, setWhitelist] = useState<Whitelist>();

  const fetchWhitelist = () => {
    const q = query(collection(firestoreDB, "whitelist"), where("whitelistId",  "==", params.whitelistId) );
    const unsubscribe = onSnapshot(q, (querySnapshot: any) => {
      const res: Array<object> = [];
      
      querySnapshot.forEach((doc: any) => {
        res.push(doc.data());
      });
      const data: Whitelist = res[0] as Whitelist;
      setWhitelist(data);
    });
  };

  const save = async(event: any) => {
    event.preventDefault();
    if (whitelist && params.whitelistId) {
      whitelist.whitelistId = params.whitelistId;
    }
    await firebase.updateWhitelist(whitelist as any);
    navigate('/whitelist');
  };

  const handleChange = (field: any) => (value: any) => {
    const data = whitelist as any;
    setWhitelist({ ...data, [field]: value });
  };

  useEffect(() => {
    fetchWhitelist();
  }, []);
  

  return (
    <div className="ui form" style={{padding: '10px 24px'}}>
      <h3>{whitelist?.createdDate ? 'Edit' : 'New'} Whitelist Item</h3>
        <form id="form" onSubmit={save}>
          <div className="one fields" style={{ marginTop: "24px" }}>
            <TextField
                label="Company Name"
                onChange={handleChange("companyName")}
                value={whitelist?.companyName}
            />
          </div>

          <div className="one fields" style={{ marginTop: "24px" }}>
            <TextField
              label="Category"
              onChange={handleChange("category")}
              value={whitelist?.category}
            />
          </div>

          <div className="one fields" style={{ marginTop: "24px" }}>
            <TextField
              label="Remark"
              onChange={handleChange("remark")}
              value={whitelist?.remark}
              rows={6}
            />
          </div>

          <button 
            className="ui primary button floated"
            style={{ marginBottom: "20px" }}
            type="submit" 
            form="form" 
            value="Save">Save</button>
        </form>
    </div>
  );
};

export default WhitelistEdit;
