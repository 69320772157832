import Grid from "@mui/material/Grid";
// import Box from '@mui/material/Box';
import { language } from "../../language";
import { useNavigate } from "react-router-dom";

import React from "react";
import MarginBox from "../MarginBox";
import "./HeaderComponent.scss";
import mySvg from "../../assets/esg-wf.svg";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import { Button } from "@mui/material";

const HeaderComponent = (props: any) => {
  let navigate = useNavigate();

  const goToAdminPage = () => {
    if (props.isLogin) {
      return navigate("/admin", { replace: true });
    } else return;
  };

  const getMousePointer = () => {
    return props.isLogin ? "pointer" : "";
  };

  const logout = () => {
    return props.logoutFun();
  };

  return (
    <div className="header-component">
      <div className="blue-line" />

      <div className="login-bar" style={{
        display: "flex",
        flexDirection: "row-reverse",
        height: "80px",
        paddingTop: "20px",
        paddingBottom: "20px",
      }}>
        <ToggleButtonGroup
          exclusive
          value={props.language}
          onChange={(_e, newLang) =>
            newLang !== null ? props.setLanguage(newLang) : null
          }
          aria-label="change language"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            marginLeft: "34px",
            marginRight: "160px",
          }}>
          <ToggleButton value="en" aria-label="language en">
            EN
          </ToggleButton>
          <ToggleButton value="de" aria-label="language de">
            DE
          </ToggleButton>
        </ToggleButtonGroup>

        {props.isLogin ? <Button onClick={logout}>Logout</Button> : null}

        <div className="userName" style={{
          padding: "6px 18px 6px 18px",
          fontWeight: "500",
          lineHeight: "1.75",
          color: "rgba(0, 0, 0, 0.6)",
          fontSize: "14px",
          justifyContent: "center",
        }}>
          {props.isLogin ? props.userEmail : null}
        </div>
      </div>

      <MarginBox>
        <Grid
          container
          spacing={0}
          style={{
            height: "100%",
            marginLeft: "0",
            marginRight: "0",
            marginBottom: "0",
            marginTop: "0",
          }}>
          <Grid
            item
            xs={6}
            style={
              {
                // border: "1px solid red"
              }
            }>
            <img
              src={mySvg}
              style={{ cursor: getMousePointer() }}
              onClick={goToAdminPage}
            />
          </Grid>
          <Grid
            item
            xs={6}
            style={
              {
                // border: "1px solid red"
              }
            }>
            <div
              style={{
                borderLeft: "1px solid rgba(50, 50, 50, 0.2)",
                paddingLeft: "32px",
              }}>
            </div>
          </Grid>
        </Grid>
      </MarginBox>
    </div>
  );
};

export default HeaderComponent;
