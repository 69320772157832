import React, { useEffect, useState } from "react";
import "./CandidatesPage.scss";
import firebase, { firestoreDB } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { BlueButton } from "../../components/Button/Button";
import trashSVG from "../../assets/trash.svg";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { IData, STATUS } from "../../interfaces/interfaces";
import { Box, Chip, CircularProgress, Tooltip } from "@mui/material";
import { ICandidate } from "../CandidatePage/CandidatePage";
import DeleteModal from "../../components/DeleteModal/DeleteModal";
import { NavTabs } from "../../components/NavTabs/NavTabs";
import { uuidv4 } from "@firebase/util";
import DropDown from "../../components/DropDown/DropDown";
import { CANDIDATES_FILTER_KEY, CONTRACT_STATUSES } from "../../constants";

const CandidatesPage = (props: any) => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [openRequest, setOpenRequest] = React.useState<IData | null>();
  const [openDelete, setOpenDelete] = useState(false);
  const [loading, setLoading] = useState(Boolean);
  const [requests, setRequests] = useState(Array<IData>);
  const [candidates, setCandidates] = useState(Array<any>);

  const [toDeleteReq, setToDeleteReq] = React.useState<IData | null>();
  const [toDeleteCand, setToDeleteCand] = React.useState<ICandidate | null>();
  const savedFilters = localStorage.getItem(CANDIDATES_FILTER_KEY);
  const [statusFilter, setStatusFilter] = useState(savedFilters ? JSON.parse(savedFilters) : [STATUS.ACTIVE, STATUS.PROSPECT]);

  const setSelected = (request: IData, selected: Array<String>) => {
    firebase.updateRequest(request.id, { ...request, candidates: selected });
  };

  const deleteRequest = (request: IData) => {
    firebase.deleteRequest(request.id);
  };

  const fetchCandidates = async (filters: any) => {
    setLoading(true);
    localStorage.setItem(CANDIDATES_FILTER_KEY, JSON.stringify(filters));
    setCandidates(await firebase.getCandidates(filters) as ICandidate[]);
    setLoading(false);
  };
  const deleteCandidate = (candidate: any) => {
    firebase.deleteCandidate(candidate.id);
  };

  const formatCandidate = (cand: any, isActive: boolean, index: number) => {
    const date = formatDate(cand);
    return (
      <tr key={'req_' + index}>
        <td>{cand.name}</td>
        <td onClick={() => navigate(`/candidate/${cand.id}`)}
            style={{
              textDecoration: "underline",
              color: "#1E69F0",
              width: "25%",
            }}>{window.location.origin}/candidate/{cand.id}</td>
        <td>
          {cand.status ?
          <Chip className={`status_chip status_chip_${cand.status.toLowerCase()}`} label={cand.status} color="success" variant="outlined" />
          : '-'}
        </td>

        <td>
          <Tooltip title={"Delete Candidate"}>
            <img
              src={trashSVG}
              onClick={() => handleOpenDeleteCand(cand)}
              style={{ marginLeft: "10px", marginRight: "0" }}
            />
          </Tooltip>
        </td>
      </tr>
    );
  };

  const filter = (data: any) => {
    setStatusFilter(data);
    fetchCandidates(data);
  };

  useEffect(() => {
    fetchCandidates(statusFilter);
  }, []);

  const activeCandidates = candidates.filter((x) => x.active);
  const formatDate = (d: any) => {
    return d.createdDate ? d.createdDate.split("T")[0] : "";
  };

  const handleOpenDeleteCand = (cand: ICandidate) => {
    setToDeleteCand(cand);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setToDeleteCand(null);
    setToDeleteReq(null);
    setOpenDelete(false);
  };

  const confirmDelete = () => {
    if (toDeleteCand) {
      deleteCandidate(toDeleteCand);
    } else if (toDeleteReq) {
      deleteRequest(toDeleteReq);
    }
    handleCloseDelete();
  };

  return (
    <div className="table-default">
      <DeleteModal
        open={openDelete}
        handleClose={handleCloseDelete}
        confirmDelete={confirmDelete}
        toDeleteCand={toDeleteCand}
        toDeleteReq={toDeleteReq}
      />
      <NavTabs userData={props.userData}  />
      <div style={{ width: "100%", display: 'flex' }}>
        <BlueButton
          label="New Candidate"
          onClick={() => navigate(`/candidate/${uuidv4()}`)}
          style={{ marginBottom: "20px" }}
        />
        <div
          style={{marginLeft: 'auto', marginTop: '-14px', width: '300px'}}>
          <DropDown
            label="Status"
            options={CONTRACT_STATUSES.map((e) => {
              return { key: e, value: e, text: e };
            })}
            onChange={filter}
            value={statusFilter}
            multiple={true}
          />
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>Candidate Name</th>
            <th>URL</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          {activeCandidates.map((d, idx) => {
            return formatCandidate(d, true, idx);
          })}
        </tbody>
      </table>
      {loading ?
      <Box sx={{ display: 'flex' }}>
        <CircularProgress sx={{margin: '24px auto'}} />
      </Box>
      : <></>}
    </div>
  );
};

export default CandidatesPage;
