import React, { useEffect, useState } from "react";
import "./MarginMonthlyReport.scss";
import { NavTabs } from "../../../components/NavTabs/NavTabs";
import firebase from "../../../firebase";
import DropDown from "../../../components/DropDown/DropDown";
import { REPORTS_MONTHS, REPORTS_YEARS } from "../../../constants";
import CheckBox from "../../../components/Checkbox/Checkbox";
import { Box, CircularProgress, Tooltip } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from "react-router-dom";
import { formatValues } from "../../../components/Helpers/helpers";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { getFileShown, openStorageFile } from "../../../util/fileUtil";
import InfoSVG from "../../../assets/info.svg";
import { Contribution } from "../../../interfaces/interfaces";

const EMPTY_REPORT = {
  contracts: [],
  summary: {
    marginPercentage: 0,
    hours: 0,
    costs: 0,
    margin: 0,
    revenue: 0,
    contributors: []
  }
} as any;

const MarginMonthlyReport = (props: any) => {
  const navigate = useNavigate();
  const [report, setReport] = useState({...EMPTY_REPORT});
  const [contributors, setContributors] = useState([]);
  const [month, setMonth] = useState(new Date().getMonth());
  const [year, setYear] = useState(new Date().getFullYear());
  const [forecast, setForecast] = useState(true);
  const [loading, setLoading] = useState(true);

  const getMarginMonthlyReport = async () => {
    setReport({...EMPTY_REPORT});
    const reqData: any = await firebase.getMarginMonthlyReport(month, year, forecast);
    if (!Array.isArray(reqData)) {
      return;
    }
    setReport(prepareReport(reqData));
  };

  const filter = (field: string) => (value: any) => {
    switch(field) {
      case 'year':
        setYear(value);
      break;

      case 'month':
        setMonth(value);
      break;

      case 'forecast':
        setForecast(!forecast);
      break;
    }
  };

  const prepareReport = (data: any) => {
    const allContributors: Set<string> = new Set();
    let totalHours = 0;
    let totalCosts = 0;
    let totalMarginPercentage = 0;
    let totalMargin = 0;
    let totalRevenue = 0;

    data.forEach((contract: any) => {
      contract.contributions.forEach((c: any) => allContributors.add(c.contributorName));
      totalHours += parseFloat(contract.hours);
      totalCosts += parseFloat(contract.costs);
      totalMarginPercentage += parseFloat(contract.marginPercentage);
      totalMargin += parseFloat(contract.margin);
      totalRevenue += parseFloat(contract.revenue);
    });

    const allContributorsArr = Array.from(allContributors) as any;
    setContributors(allContributorsArr);

    const summaryContributors: any = {};
    let allContributions: Contribution[] = [];

    allContributorsArr.forEach((contributorName: string) => {
      data.forEach((contract: any) => {
        const foundContributions = contract.contributions.filter((c: any) => c.contributorName === contributorName);
        allContributions = allContributions.concat(foundContributions);
        if (!summaryContributors[contributorName]) {
          summaryContributors[contributorName] = 0;
        }
        foundContributions.forEach((foundContribution: any) => {
            summaryContributors[contributorName] += parseFloat(foundContribution.margin);
            if (!contract[contributorName]) {
              contract[contributorName] = 0;
            }
            contract[contributorName] += parseFloat(foundContribution.margin);
            if (foundContribution.payoutMonth?.description && contract[contributorName] > 0) {
              contract[contributorName + '_desc'] = `Amount: ${foundContribution.fixAmount}, Description: ${foundContribution.payoutMonth?.description}`;
            }
        });
        if (foundContributions.length === 0) {
          contract[contributorName] = null;
        }
      });
    });

    let esgwSum = 0;
    if (props.userData?.role === 'operator') {
      data.forEach((contract: any) => {
        contract.esgwMargin = contract.margin;
        allContributorsArr.forEach((contributor: string) => {
          if (contract[contributor]) {
            contract.esgwMargin -= contract[contributor];
          }
        });
        esgwSum += contract.esgwMargin;
      });
    }

    let extraCosts: any[] = [];
    data.filter((c: any) => c.extraCosts).forEach((contract: any) => extraCosts = extraCosts.concat(contract.extraCosts));
    for (let cost of extraCosts) {
      const found: any = allContributions.find((c: any) => c.contributorId === cost.contributorId);
      if (found) {
        cost.contributorName = found.contributorName;
      }
    }

    setLoading(false);
    return {
      contracts: data,
      summary: {
        marginPercentage: (totalRevenue - totalCosts) / totalRevenue * 100,
        hours: totalHours,
        costs: totalCosts,
        margin: totalMargin,
        revenue: totalRevenue,
        contributors: summaryContributors,
        esgw: esgwSum,
        extraCosts: extraCosts
      }
    };
  };

  const contributorCostTotal = (name: string, summary: any) => {
    const amount = summary.extraCosts.reduce((prev: any, current: any) => {
      if (current.contributorName === name) {
        return prev + parseFloat(current.amount);
      }
      return prev;
    }, 0);
    return summary.contributors[name] - amount;
  };

  useEffect(() => {
    getMarginMonthlyReport();
  }, [month, year, forecast]);


  return (
    <div className="table-default" style={{overflowX: 'auto', marginBottom: '24px'}}>
      <NavTabs userData={props.userData} />
      <div style={{ width: "100%"}}>
        <a className="backBtn" onClick={() => navigate(-1)}>
            <ArrowBackIosIcon />
            Back
        </a>
        <div style={{gap: '16px', display: 'flex', marginTop: '16px', marginLeft: '10px', width: '300px'}}>
          <DropDown
            label="Month"
            options={REPORTS_MONTHS.map((e) => {
              return { key: e.label, value: e.value, text: e.label };
            })}
            onChange={filter('month')}
            value={month}
          />

          <DropDown
            label="Year"
            options={REPORTS_YEARS.map((e) => {
              return { key: e, value: e, text: e };
            })}
            onChange={filter('year')}
            value={year}
          />

          <div className="forecastCheckbox">
            <CheckBox
              onChange={filter("forecast")}
              checked={forecast}
              option="Forecast"
              style={{marginTop: '30px'}}  
            />
          </div>
        </div>
        {loading ?
        <Box sx={{ display: 'flex' }}>
          <CircularProgress sx={{margin: '24px auto'}} />
        </Box>
        :
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Hours</th>
              <th>Charge Rate</th>
              <th>Revenue</th>
              <th>Pay Rate</th>
              <th>Costs</th>
              <th>Margin %</th>
              <th>Margin CHF</th>
              {contributors.map((contributor: string, index: number) => (
                <th key={`head_${index}`}>{contributor}</th>
              ))}
              
              {props.userData?.role === 'operator' ?
              <th>ESGW</th>
              : null}
              <th>Invoice</th>
            </tr>
          </thead>

          <tbody>
            {report.contracts.map((row: any) => (
            <tr className={row.isForecast ? 'forecast' : ''} key={`contract_${row.name}`}>
              <td>{row.name}</td>
              <td>{row.hours}h</td>
              <td>{formatValues(row.chargeRate)}</td>
              <td>{formatValues(row.revenue)}</td>
              <td>{formatValues(row.payRate)}</td>
              <td>{formatValues(row.costs)}</td>
              <td>{row.marginPercentage.toFixed(2)}%</td>
              <td>{formatValues(row.margin)}</td>

              {contributors.map((name: any, nIndex: number) => 
              <td key={`contrib_${nIndex}`}>
                <span>
                {row[name + '_desc'] ?
                <Tooltip title={row[name+'_desc']}>
                    <img
                      src={InfoSVG}
                      style={{
                        marginLeft: "0",
                        marginRight: "5px",
                        width: "16px",
                        verticalAlign: "middle"
                      }}
                    />
                  </Tooltip>
                  : null}
                {row[name] ? formatValues(row[name]) : '-'}
                </span>
              </td>
              )}

              {props.userData?.role === 'operator' ?
              <td>{formatValues(row.esgwMargin)}</td>
              : null}
              <td>
                {row.invoice ? (
                  <>
                    <span style={{ verticalAlign: "middle" }}>{row.invoice.name}</span>
                    <OpenInNewIcon
                      style={{ verticalAlign: "middle", padding: "0" }}
                      onClick={() => window.open(getFileShown(row.invoice as any))}
                    />
                  </>
                ) : '-'}
              </td>
            </tr>
            ))}
            {report?.summary?.hours ?
            <>
              <tr className="total-row">
                <td>Total Margin</td>
                <td>{formatValues(report.summary.hours)}h</td>
                <td></td>
                <td>{formatValues(report.summary.revenue)}</td>
                <td></td>
                <td>{formatValues(report.summary.costs)}</td>
                <td>{report.summary.marginPercentage.toFixed(2)}%</td>
                <td>{formatValues(report.summary.margin)}</td>

                {contributors.map((name: any, nIndex: number) => (
                  <td key={`contrib_summary_${nIndex}`}>
                    {report.summary.contributors[name] > 0 ? formatValues(report.summary.contributors[name]) : '-'}
                  </td>
                ))}
                {props.userData?.role === 'operator' ?
                <td>
                  {formatValues(report.summary.esgw)}
                </td>
                : null}

                <td></td>
              </tr>
              
              {report?.summary?.extraCosts.map((cost: any, nIndex: number) => <>
              <tr key={`c_summary_${nIndex}`} style={{border: 'none'}}>
                <td>{cost.description}</td>
                <td colSpan={7}></td>
                {contributors.map((name: any, nIndex: number) => <>
                  <td style={{color: 'red'}} key={`cost_${nIndex}`}>
                    {cost.contributorName === name ?
                      <span>
                      -{formatValues(cost.amount)}
                      </span>
                    : null}
                  </td>
                </>
                )}
                {props.userData?.role === 'operator' ?
                <td></td>
                : null}

                {contributors.map((name: any, nIndex: number) => <>
                    {cost.contributorName === name ?
                    <td>
                      <span  style={{display: 'flex'}}>
                        {cost.file ? (
                        <>
                          <span style={{ verticalAlign: "middle" }}>{cost.file.name}</span>
                          <OpenInNewIcon
                            style={{ verticalAlign: "middle", marginLeft: "5px" }}
                            onClick={() => openStorageFile(cost.file as any)}
                          />
                        </>
                        ) : '-'}
                    </span>
                    </td>
                    : null}
                </>
                )}
              </tr></>)}

              <tr className="total-row">
                <td>Total Invoice</td>
                <td colSpan={7}></td>
                {contributors.map((name: any, nIndex: number) => 
                 <td>{formatValues(contributorCostTotal(name, report?.summary))}</td>
                )}
                <td style={{fontWeight: 'bold'}}>{}</td>
              </tr>
            </>
            :
            <tr>
              <td style={{textAlign: 'center', padding: '30px 10px', fontWeight: 'bold'}} colSpan={11 + contributors.length}>No data</td>
            </tr>
            }
          </tbody>
        </table>
        }
      </div>
  </div>
  );
};

export default MarginMonthlyReport;
