import React from "react";
import { Form, Radio } from "semantic-ui-react";
import { CSSProperties } from "react";

interface RadioButtonsProps {
  label: string;
  options: Array<string>;
  optionLabels?: Array<string>;
  name?: string;
  checked: string;
  onChange: (value: string) => void;
  error?: any;
  style?: any;
  fieldStyle?: CSSProperties;
}

const RadioButtons = ({
  label,
  options,
  name,
  optionLabels,
  onChange,
  checked,
  error,
  fieldStyle,
  ...rest
}: RadioButtonsProps) => {
  const setChange = (e: any, { value }: any) => {
    onChange(value);
  };
  const getStyle = () => {
    return fieldStyle;
  };

  const getOptionLabel = (option: string, index: number) => {
    if (optionLabels && optionLabels.length > 0) {
      return optionLabels[index];
    } else {
      return option;
    }
  };

  return (
    <div className="field" style={getStyle()}>
      {error ? (
        <label style={{ color: "red" }}>{label}</label>
      ) : (
        <label>{label}</label>
      )}
      <Form>
        <div className="inline fields drop">
          {options.map((option, index) => (
            <Form.Field key={index}>
              <Radio
                label={getOptionLabel(option, index)}
                checked={checked === option}
                value={option}
                onChange={setChange}
                {...rest}
              />
            </Form.Field>
          ))}
        </div>
      </Form>
    </div>
  );
};
export default RadioButtons;
