import Dropzone from "../../../components/Dropzone/Dropzone";
import TextField from "../../../components/TextField/TextField";
import { Booking, Contribution, Contributor, FileDTO, Service } from "../../../interfaces/interfaces";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import trashSVG from "../../../assets/trash.svg";
import { getFileShown } from "../../../util/fileUtil";
import { Tooltip } from "@mui/material";
import DropDown from "../../../components/DropDown/DropDown";
import { REPORTS_MONTHS, REPORTS_YEARS } from "../../../constants";

export const ContributionRow = (props: {contributors: Contributor[], services: Service[], contribution: Contribution, onChange: any}) => {
  if (!props.contribution.payoutMonth) {
    props.contribution.payoutMonth = {};
  }
    const handleChange = (field: any) => (value: any) => {
      const contrib = props.contribution as any;
      contrib[field] = value;
      props.onChange();
    };

    const handlePayoutMonthChange = (field: any) => (value: any) => {
      const contrib = {...props.contribution as any};
      contrib.payoutMonth.month = value;
      props.onChange();
    };

    const handlePayoutYearChange = (field: any) => (value: any) => {
      const contrib = {...props.contribution as any};
      
      contrib.payoutMonth.year = value;
      props.onChange();
    };

    const handlePayoutYDescriptionChange = (field: any) => (value: any) => {
      const contrib = {...props.contribution as any};
      
      contrib.payoutMonth.description = value;
      props.onChange();
    };
  
    const deleteContribution = (booking: any) => {
      booking.remove = true;
      props.onChange();
    };
  
    return (
      <tr>
        <td>
            {(props.contribution.editing ?
              <>
                <TextField
                    onChange={handleChange("percentage")}
                    value={props.contribution?.percentage}
                    type="number"
                />
              </>
              : <>
              {props.contribution.percentage}%
                </> 
            )}
        </td>

        <td>
            {(props.contribution.editing ?
            <>
              <TextField
                onChange={handleChange("fixAmount")}
                value={props.contribution?.fixAmount}
                type="number"
              />
            </>
              : <>
              {props.contribution.fixAmount ? props.contribution.fixAmount + ' CHF' : '-'}
                </> 
            )}
        </td>
        <td>
            {(props.contribution.fixAmount ? <>
            <div style={{display: 'flex', gap: '10px'}}>
              <DropDown
                label="Year"
                options={REPORTS_YEARS.map((e) => {
                  return { key: e, value: e, text: e };
                })}
                onChange={handlePayoutYearChange("payoutYear")}
                value={props?.contribution.payoutMonth?.year}
                readonly={!props.contribution.editing}
              />

              <DropDown
                label="Month"
                options={REPORTS_MONTHS.map((e) => {
                  return { key: e.value, value: e.value, text: e.label };
                })}
                onChange={handlePayoutMonthChange("payoutMonth")}
                value={props?.contribution.payoutMonth?.month}
                readonly={!props.contribution.editing}
              />
            </div>
            {props.contribution.editing ?
              <TextField 
                  label="Description"
                  onChange={handlePayoutYDescriptionChange("payoutDescription")}
                  value={props?.contribution.payoutMonth?.description}
                  rows={3}
              />
              : <>
                <b>Description</b><br />
                <span>{props?.contribution.payoutMonth?.description}</span>
              </>}
            </>: null)}
        </td>

        <td>
            <DropDown
              options={props.contributors.map((e) => {
                return { key: e.contributorId, value: e.contributorId, text: e.name };
              })}
              onChange={handleChange("contributorId")}
              value={props?.contribution.contributorId}
              readonly={!props.contribution.editing}
            />
        </td>

        <td>
            <DropDown
              options={props.services.map((e) => {
                return { key: e.serviceId, value: e.serviceId, text: e.type };
              })}
              onChange={handleChange("serviceId")}
              value={props?.contribution.serviceId}
              readonly={!props.contribution.editing}
            />
        </td>

        <td>
          <a style={{cursor: 'pointer'}} onClick={() => handleChange("editing")(!props.contribution.editing)}>{props.contribution.editing ? 'Close': 'Edit'}</a>
        </td>

        <td>
          <Tooltip title={`Delete`}>
            <img
              src={trashSVG}
              onClick={() => deleteContribution(props.contribution)}
              style={{
                marginLeft: "5px",
                marginRight: "5px",
                width: "16px",
                verticalAlign: "middle",
              }}
            />
          </Tooltip>
        </td>
      </tr>
    );
  };