import Dropzone from "../../../components/Dropzone/Dropzone";
import TextField from "../../../components/TextField/TextField";
import { Booking, FileDTO } from "../../../interfaces/interfaces";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import trashSVG from "../../../assets/trash.svg";
import { getFileShown, openStorageFile } from "../../../util/fileUtil";
import { CircularProgress, Tooltip } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { storage } from "../../../firebase";
import { useState } from "react";
import { ref, uploadBytes } from "firebase/storage";


export const BookingRow = (props: {booking: Booking, onChange: any}) => {
  const [uploading, setUploading] = useState(false);

  const onFileChange = async (file: any, booking: Booking) => {
    file.uploading = true;
    const fullPath = `bookings/${uuidv4()}.${file.name.split('.')[1]}`;
    const costsRef = ref(storage, fullPath);
    const res = await fetch(file.base64);
    const blob = await res.blob();
    setUploading(true);
    uploadBytes(costsRef, blob).then((snapshot: any) => {
      file.fullPath = fullPath;
      delete file.base64;
      booking.invoice = file;
      props.onChange();
      setUploading(false);
    })
  };
  
    const handleBookingChange = (field: string, booking: any, value: any) => {
      switch (field) {
        case 'year':
        case 'month':
          booking['monthYear'][field] = (value * 1);
        break;
  
        default:
          booking[field] = value;
      }
      props.onChange();
    };
  
    const deleteBooking = (booking: any) => {
      booking.remove = true;
      props.onChange();
    };

    const deleteFile = () => {
      if (props.booking?.invoice && !props.booking?.invoice?.base64) {
        props.booking.invoice.delete = true;
        props.onChange();
        return;
      }
      if (props.booking.invoice?.base64) {
        delete props.booking.invoice;
        props.onChange();
      }
    };
  
    return (
      <tr>
        <td>
            {(props.booking.editing ?
            <div className="two fields">
              <TextField
                label="Month"
                onChange={(value: any, e: Event) =>  handleBookingChange("month", props.booking, value)}
                value={props.booking?.monthYear.month}
                type="number"
              />
              <TextField
                label="Year"
                onChange={(value: any, e: Event) => handleBookingChange("year", props.booking, value)}
                value={props.booking?.monthYear.year}
                type="number"
              />
            </div>
            : <>
              {new Date(`${props.booking.monthYear.year}-${props.booking.monthYear.month < 10 ? '0' + props.booking.monthYear.month : props.booking.monthYear.month}-01`).toLocaleString('en-us', {  month: 'long' })}  {props.booking.monthYear.year}
            </> )}
        </td>
  
        <td>
          {(props.booking.editing ?
            <div className="one fields">
              <TextField
                label="Hours"
                onChange={(value: any, e: Event) => handleBookingChange("workingTime", props.booking, value)}
                value={props.booking?.workingTime}
                type="number"
              />
            </div>
            : <>
              {props.booking.workingTime}
            </> )}
        </td>
  
        <td>
          {(props.booking.editing ?
            <div className="one fields bookingInvoiceDropzone">
              {uploading ?
              <CircularProgress sx={{margin: '0'}} />
              :
              <Dropzone
                label={props.booking.invoice && (!props.booking.invoice.delete || props.booking.invoice.base64) ? props.booking.invoice.name : 'Select a file'}
                onDataChange={(file: FileDTO) => onFileChange(file, props.booking)}
              />
              }
              {props.booking.invoice && (!props.booking.invoice.delete || props.booking.invoice.base64) ?
                <Tooltip title={`Delete File ${props.booking.invoice.name}`}>
                  <img
                    src={trashSVG}
                    onClick={() => deleteFile()}
                    style={{
                      marginLeft: "6px",
                      marginRight: "4px",
                      width: "16px",
                      cursor: "pointer",
                      marginTop: "15px",
                      verticalAlign: "middle",
                    }}
                  />
                </Tooltip>
                : null}
            </div>
            : <>
                {props.booking.invoice ? (
                <>
                  <span style={{ verticalAlign: "middle" }}>{props.booking.invoice.name}</span>
                  {props.booking.invoice?.base64 ?
                    <OpenInNewIcon
                      style={{ verticalAlign: "middle", marginLeft: "5px" }}
                      onClick={() => window.open(getFileShown(props.booking.invoice as any))}
                    />
                  :
                    <OpenInNewIcon
                      style={{ verticalAlign: "middle", marginLeft: "5px" }}
                      onClick={() => openStorageFile(props.booking.invoice as any)}
                    />
                  }
                </>
                ) : '-'}
             </>
            )}
        </td>
        <td>
          <a style={{cursor: 'pointer'}} onClick={() => handleBookingChange("editing", props.booking, !props.booking.editing)}>{props.booking.editing ? 'Close': 'Edit'}</a>
        </td>
        <td>
          <Tooltip title={`Delete`}>
            <img
              src={trashSVG}
              onClick={() => deleteBooking(props.booking)}
              style={{
                marginLeft: "5px",
                marginRight: "5px",
                width: "16px",
                verticalAlign: "middle",
              }}
            />
          </Tooltip>
        </td>
      </tr>
    );
  };