import { ICandidate, IDataType } from "../CandidatePage/CandidatePage";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { getFileShown } from "../../util/fileUtil";
import { language } from "../../language";

const SelectCandidateForm = ({
  lang,
  candidates,
}: {
  lang: string;
  candidates: ICandidate[];
}) => {
  const [tableTitles, setTableTitles] = useState<string[]>([]);

  const updateTableTitles = () => {
    setTableTitles([
      language["tableFields"][lang]["Candidate"],
      language["tableFields"][lang]["Title"],
      language["tableFields"][lang]["Start"],
      language["tableFields"][lang]["Rate"],
      language["tableFields"][lang]["Available"],
      language["tableFields"][lang]["Files"],
    ]);
  };

  useEffect(() => {
    updateTableTitles();
  }, [lang]);

  return (
    <>
      <h2>Our Candidates</h2>
      <hr />
      <TableContainer component={Paper}>
        <Table
          // sx={{ minWidth: 650 }}
          aria-label="our candidates table"
        >
          <TableHead style={{ background: "#F2F2F2" }}>
            <TableRow>
              {tableTitles.map((title: string) => {
                return (
                  <TableCell align="left" key={`table-header-${title}`}>
                    <b>{title}</b>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {candidates.map((candidate: ICandidate) => {
              return (
                <TableRow
                  key={`table-row-${candidate.name}`}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {candidate.name}
                  </TableCell>
                  <TableCell align="left">{candidate.title}</TableCell>
                  <TableCell align="left">{candidate.start}</TableCell>
                  <TableCell align="left">{candidate.payRate}</TableCell>
                  <TableCell align="left">{candidate.interview}</TableCell>
                  <TableCell align="left">
                    <div key={candidate.id}>
                      {candidate.files && candidate.files.length > 0
                        ? candidate.files.map((f: IDataType) => {
                            return (
                              <Button
                                onClick={() => window.open(getFileShown(f))}
                                style={{ display: "flex" }}
                                key={f.name}
                              >
                                {f.name}
                              </Button>
                            );
                          })
                        : null}
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default SelectCandidateForm;
