
interface ButtonProps {
    label?: string
    onClick: (value: any) => void,
    error?: any,
    [x:string]: any;
}
export const BlueButton = ({ label, onClick, ...props }: ButtonProps) => {
    return (
      <button className="ui primary button floated" onClick={onClick} {...props}>
        {label}
      </button>
    );
  };
