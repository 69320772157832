import { IDataType } from "../pages/CandidatePage/CandidatePage";
import { base64StringToBlob, createObjectURL } from "blob-util";
import { FileDTO } from "../interfaces/interfaces";
import { getBlob, getDownloadURL, ref } from "firebase/storage";
import { storage } from "../firebase";

export const getFileShown = (file: IDataType) => {
  const t = file.base64.split("base64,")[1];
  const blob = base64StringToBlob(t, file.type);
  return createObjectURL(blob);
};

export const openStorageFile = async (file: FileDTO) => {
  getDownloadURL(ref(storage, file.fullPath))
  .then((url) => {
    window.open(url);
  })
  .catch((error) => {
    // Handle any errors
  });
};
