import React, { useEffect, useState } from "react";
import "./JobOpeningsPage.scss";
import firebase, { firestoreDB } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { BlueButton } from "../../components/Button/Button";
import trashSVG from "../../assets/trash.svg";
import { collection, onSnapshot, query } from "firebase/firestore";
import { IData, STATUS } from "../../interfaces/interfaces";
import { Box, Chip, CircularProgress, Tooltip } from "@mui/material";
import { ICandidate } from "../CandidatePage/CandidatePage";
import DeleteModal from "../../components/DeleteModal/DeleteModal";
import { NavTabs } from "../../components/NavTabs/NavTabs";
import CandidateDialog from "./CandidateDialog";
import { CONTRACT_STATUSES, JOB_OPENINGS_FILTER_KEY } from "../../constants";
import DropDown from "../../components/DropDown/DropDown";


const JobOpeningsPage = (props: any) => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [openRequest, setOpenRequest] = React.useState<IData | null>();
  const [openDelete, setOpenDelete] = useState(false);

  const [requests, setRequests] = useState(Array<IData>);
  const [candidates, setCandidates] = useState(Array<any>);

  const [toDeleteReq, setToDeleteReq] = React.useState<IData | null>();
  const [toDeleteCand, setToDeleteCand] = React.useState<ICandidate | null>();
  const savedFilters = localStorage.getItem(JOB_OPENINGS_FILTER_KEY);

  const [statusFilter, setStatusFilter] = useState(savedFilters ? JSON.parse(savedFilters) : [STATUS.ACTIVE, STATUS.PROSPECT]);
  const [loading, setLoading] = useState(true);

  const setSelected = (request: IData, selected: Array<String>) => {
    firebase.updateRequest(request.id, { ...request, candidates: selected });
  };

  const deleteRequest = (request: IData) => {
    firebase.deleteRequest(request.id).then(() => {
      fetchRequests(statusFilter);
    });
  };

  const fetchRequests = (filters: STATUS[]) => {
    localStorage.setItem(JOB_OPENINGS_FILTER_KEY, JSON.stringify(filters));
    setLoading(true);
    firebase.getRequests(filters).then((requests: any) => {
      setRequests(requests);
      setLoading(false);
    })
  };

  const fetchCandidates = () => {
    const q = query(collection(firestoreDB, "candidate"));
    const unsubscribe = onSnapshot(q, (querySnapshot: any) => {
      const res: Array<object> = [];
      querySnapshot.forEach((doc: any) => {
        res.push(doc.data());
      });
      const t: Array<IData> = res as Array<IData>;
      setCandidates(t);
    });
  };
  const deleteCandidate = (candidate: any) => {
    firebase.deleteCandidate(candidate.id);
  };

  useEffect(() => {
    fetchRequests(statusFilter);
    fetchCandidates();
  }, []);

  const activeCandidates = candidates.filter((x) => x.active);

  const handleCloseDialog = (
    event: React.SyntheticEvent<unknown>,
    reason?: string
  ) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
    setOpenRequest(null);
  };

  const formatRequest = (d: any, isActive: boolean, index: number) => {
    return (
      <tr key={'req_' + index}>
        <td>{d.position}</td>
        <td onClick={() => navigate(`/requests/${d.id}`)}
          style={{
            textDecoration: "underline",
            color: "#1E69F0",
            width: "25%",
            cursor: 'pointer'
          }}>{window.location.origin}/requests/{d.id}</td>
        <td>{d.clientCompany}</td>
        <td>{d.contractName ?? '-'}</td>
        <td>
          {d.status ?
            <Chip className={`status_chip status_chip_${d.status.toLowerCase()}`} label={d.status} color="success" variant="outlined" />
          : '-'}
        </td>
        <td>
          <Tooltip title={"Delete Request"}>
            <img
              src={trashSVG}
              onClick={() => handleOpenDeleteReq(d)}
              style={{ marginLeft: "10px", marginRight: "0" }}
            />
          </Tooltip>
        </td>

      </tr>
    );
  };

  const filter = (data: any) => {
    setStatusFilter(data);
    fetchRequests(data);
  };

  const handleOpenDeleteReq = (req: IData) => {
    setToDeleteReq(req);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setToDeleteCand(null);
    setToDeleteReq(null);
    setOpenDelete(false);
  };

  const confirmDelete = () => {
    if (toDeleteCand) {
      deleteCandidate(toDeleteCand);
    } else if (toDeleteReq) {
      deleteRequest(toDeleteReq);
    }
    handleCloseDelete();
  };

  return (
    <div className="table-default">
      <DeleteModal
        open={openDelete}
        handleClose={handleCloseDelete}
        confirmDelete={confirmDelete}
        toDeleteCand={toDeleteCand}
        toDeleteReq={toDeleteReq}
      />
      {candidates.length > 0 && openRequest ? (
        <CandidateDialog
          isOpen={open}
          handleClose={handleCloseDialog}
          candidates={activeCandidates}
          request={openRequest}
          setSelected={(selected: Array<String>) =>
            setSelected(openRequest, selected)
          }
        />
      ) : null}

      <div>
        <NavTabs userData={props.userData} />

        <div style={{ width: "100%", display: 'flex' }}>
          <BlueButton
            label="New Request"
            onClick={() => navigate("/")}
            style={{ marginBottom: "20px" }}
          />
          <div style={{marginLeft: 'auto', marginTop: '-14px'}}>
            <DropDown
              label="Status"
              options={CONTRACT_STATUSES.map((e) => {
                return { key: e, value: e, text: e };
              })}
              onChange={filter}
              value={statusFilter}
              multiple={true}
            />
          </div>
        </div>
      </div>

      <table>
        <thead>
          <tr>
            <th>Job Name</th>
            <th>URL</th>
            <th>Company Name</th>
            <th>Contract Name</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          {loading ?
            <tr>
              <td colSpan={5}>
                <Box sx={{ display: 'flex' }}>
                  <CircularProgress sx={{margin: '24px auto'}} />
                </Box>
              </td>
            </tr>

            :
            requests
            .sort((objA, objB) => Number(objB.number) - Number(objA.number))
            .map((d, idx) => {
              return formatRequest(d, true, idx);
            })
          }
        </tbody>
      </table>
    </div>
  );
};

export default JobOpeningsPage;
