import React, { useEffect, useState } from "react";
import "./ContractEdit.scss";
import firebase, { firebaseApp, firestoreDB, storage } from "../../../firebase";
import { useNavigate, useParams } from "react-router-dom";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import TextField from "../../../components/TextField/TextField";
import { Contract, Contributor, Cost, IData, Service } from "../../../interfaces/interfaces";
import Datepicker from "../../../components/Datepicker/Datepicker";
import { CONTRACT_STATUSES } from "../../../constants";
import DropDown from "../../../components/DropDown/DropDown";
import { BlueButton } from "../../../components/Button/Button";
import { ICandidate } from "../../CandidatePage/CandidatePage";
import { uuidv4 } from "@firebase/util";
import { BookingRow } from "./BookingRow";
import { ContributionRow } from "./ContributionRow";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box, CircularProgress } from "@mui/material";
import { CostRow } from "./CostRow";
import { deleteObject, ref } from "firebase/storage";



const ContractEdit = (props: any) => {
  let params = useParams();
  const navigate = useNavigate();
  if (!props.isLogin) {
    navigate('/login');
  }

  const [loading, setLoading] = useState(Boolean);
  const [contract, setContract] = useState<Contract>();
  const [candidates, setCandidates] = useState<ICandidate[]>([]);
  const [jobOpenings, setJobOpenings] = useState<IData[]>([]);
  const [contributors, setContributors] = useState<Contributor[]>([]);
  const [services, setServices] = useState<Service[]>([]);


  const fetchContract = () => {
    setLoading(true);
    const q = query(collection(firestoreDB, "contract"), where("contractId",  "==", params.contractId) );
    const unsubscribe = onSnapshot(q, (querySnapshot: any) => {
      const res: Array<object> = [];
      
      querySnapshot.forEach((doc: any) => {
        res.push(doc.data());
      });
      const data: Contract = res[0] as Contract;
      setContract(data);
      setLoading(false);
    });
  };

  const fetchCandidates = () => {
    const q = query(collection(firestoreDB, "candidate"));
    const unsubscribe = onSnapshot(q, (querySnapshot: any) => {
      const res: Array<object> = [];
      
      querySnapshot.forEach((doc: any) => {
        res.push(doc.data());
      });
      setCandidates(res as ICandidate[]);
    });
  };

  const fetchContributors = () => {
    const q = query(collection(firestoreDB, "contributor"), where("status",  "==", "Active"));
    const unsubscribe = onSnapshot(q, (querySnapshot: any) => {
      const res: Array<object> = [];
      
      querySnapshot.forEach((doc: any) => {
        res.push(doc.data());
      });
      setContributors(res as Contributor[]);
    });
  };

  const fetchServices = () => {
    const q = query(collection(firestoreDB, "services"));
    const unsubscribe = onSnapshot(q, (querySnapshot: any) => {
      const res: Array<object> = [];
      
      querySnapshot.forEach((doc: any) => {
        res.push(doc.data());
      });
      setServices(res as Service[]);
    });
  };

  const fetchJobOpenings = () => {
    const q = query(collection(firestoreDB, "request"));
    const unsubscribe = onSnapshot(q, (querySnapshot: any) => {
      const res: Array<object> = [];
      
      querySnapshot.forEach((doc: any) => {
        res.push(doc.data());
      });
      setJobOpenings(res as IData[]);
    });
  };

  const addBooking = () => {
    if (contract) {
      if (!contract.bookings) {
        contract.bookings = [];
      }

      setContract({...contract,
        bookings: [
          ...contract.bookings,
          {
            bookingId: uuidv4(),
            editing: true,
            monthYear: {
              month: new Date().getMonth(),
              year: new Date().getFullYear()
            }
          }
        ]
      })
    }
  };

  const addCost = () => {
    if (contract) {
      if (!contract.costs) {
        contract.costs = [];
      }

      setContract({...contract,
        costs: [
          ...contract.costs,
          {
            costId: uuidv4(),
            editing: true,
            description: '',
            monthYear: {
              month: new Date().getMonth(),
              year: new Date().getFullYear()
            }
          }
        ]
      })
    }
  };

  const bookingsChanged = () => {
    if (contract) {
      const bookings = contract.bookings as any;
      setContract({...contract, bookings: [...bookings.filter((booking: any) => !booking.remove)]});
    }
  };

  const costsChanged = () => {
    if (contract) {
      const costs = contract.costs as any;
      setContract({...contract, costs: [...costs]});
    }
  };

  const addContribution = () => {
    if (contract) {
      if (!contract.contributions) {
        contract.contributions = [];
      }
      setContract({...contract,
        contributions: [
          ...contract.contributions,
          {
            contributionId: uuidv4(),
            editing: true
          }
        ]
      })
    }
  };

  const contributionsChange = () => {
    if (contract) {
      const contributions = [...contract.contributions as any];
      setContract({...contract, contributions: [...contributions.filter((contribution: any) => !contribution.remove)]});
    }
  };

  const clone = async (event: MouseEvent) => {
    event.preventDefault();
    const confirmed = window.confirm('Are you sure you want to clone this Contract?');
    if (confirmed && contract) {
      const cloned: Contract = {
        contractId: uuidv4(),
        name: `${contract.name} - Clone`,
        companyName: contract.companyName,
        startDate: contract.startDate,
        endDate: contract.endDate,
        status: contract.status,
        chargeRate: contract.chargeRate,
        hoursPerDay: contract.hoursPerDay,
        remarks: contract.remarks,
        payRate: contract.payRate,
        closingMonth: contract.closingMonth,
        workload: contract.workload,
        candidateId: contract.candidateId,
        jobOpeningId: contract.jobOpeningId,
        bookingIds: [],
        contributionIds: [],
        probability: contract.probability,
        probabilityExtension: contract.probabilityExtension,
        bookings: [],
        contributions: [],
        costs: []
      };

      setLoading(true);
      const response: any = await firebase.updateContract(cloned as any);
      navigate(`/contracts/${response.contractId}`);
      setContract(response);
      setLoading(false);
    }
  };

  const save = async(event?: any) => {
    event.preventDefault();
    setLoading(true);
    if (contract && params.contractId) {
      if (!contract.bookings) {
        contract.bookings = [];
      }

      if (!contract.contributions) {
        contract.contributions = [];
      }
      contract.contractId = params.contractId;
      contract.bookings.forEach(b => {
        if (b.invoice?.delete && !b.invoice.base64) {
          deleteObject(ref(storage, b.invoice?.fullPath));
          delete b.invoice;
        }
        delete b.editing;
      });

      contract.contributions.forEach(c => {
        delete c.editing;
      });

      if (contract.costs) {
        contract.costs.forEach((cost: Cost) => {
          delete cost.editing;
          if (cost.file?.fullPath && (cost.file?.delete || cost.remove)) {
            deleteObject(ref(storage, cost.file?.fullPath)).then(() => {
              delete cost.file;
            });
          }
        })

        contract.costs = contract.costs.filter((cost: Cost) => !cost.remove);
      }
    }
    await firebase.updateContract(contract as any);
    setLoading(false);
    navigate('/contracts');
  };

  const handleChange = (field: any) => (value: any) => {
    const data = contract as any;
    setContract({ ...data, [field]: value });
  };

  const handleDateChange = (field: string, value: any) => {
    const data = contract as any;
    setContract({ ...data, [field]: value });
  };

  useEffect(() => {
    fetchContract();
    fetchCandidates();
    fetchJobOpenings();
    fetchContributors();
    fetchServices();
  }, []);

  return (
    <div className="ui form" style={{padding: '10px 24px'}}>
      <div style={{display: 'flex'}}>
        <a className="backBtn" onClick={() => navigate(-1)}>
            <ArrowBackIosIcon />
            Back
        </a>

        {contract?.createdDate ?
        <button 
            className="ui primary button floated"
            style={{ marginLeft: 'auto' }}
            onClick={(event: any) => {clone(event)}}
            value="Clone">Clone</button>
          : null}
      </div>

      <h3>{contract?.createdDate ? 'Edit' : 'New'} Contract</h3>
      {loading ?
      <Box sx={{ display: 'flex' }}>
        <CircularProgress sx={{margin: '24px auto'}} />
      </Box>
      : 
      <form id="form" onSubmit={save}>
        <div className="two fields" style={{ marginTop: "24px" }}>
          <TextField
            label="Contract Name *"
            onChange={handleChange("name")}
            value={contract?.name}
            required={true}
          />
        </div>

        <h4>Contract details</h4>

        <div className="three fields" style={{ marginTop: "24px" }}>
          <TextField
            label="Charge Rate"
            onChange={handleChange("chargeRate")}
            value={contract?.chargeRate}
            type="number"
          />

          <TextField
            label="Hours per Day"
            onChange={handleChange("hoursPerDay")}
            value={contract?.hoursPerDay}
            type="number"
          />

          <TextField
            label="Remarks"
            onChange={handleChange("remarks")}
            value={contract?.remarks}
            rows="4"
          />
        </div>

        <div className="three fields" style={{ marginTop: "24px" }}>
          <TextField
            label="Pay Rate"
            onChange={handleChange("payRate")}
            value={contract?.payRate}
            type="number"
          />

          <TextField
            label="Closing Month"
            onChange={handleChange("closingMonth")}
            value={contract?.closingMonth}
            type="number"
          />

          <TextField
            label="Probability Prospect"
            onChange={handleChange("probability")}
            value={contract?.probability}
            type="number"
          />

          <TextField
            label="Probability Extension"
            onChange={handleChange("probabilityExtension")}
            value={contract?.probabilityExtension}
            type="number"
          />
        </div>

        <div className="two fields" style={{ marginTop: "24px" }}>
          <Datepicker
            label="Start Date"
            inputFormat="DD/MM/YYYY"
            value={contract?.startDate}
            onChange={(val: string) => handleDateChange('startDate', val)}
          />

          <Datepicker
            label="End Date"
            inputFormat="DD/MM/YYYY"
            value={contract?.endDate}
            onChange={(val: string) => handleDateChange('endDate', val)}
          />
        </div>

        <div className="two fields" style={{ marginTop: "24px" }}>
          <TextField
            label="Workload"
            onChange={handleChange("workload")}
            value={contract?.workload}
            type="number"
          />

          <DropDown
            label="Status"
            options={CONTRACT_STATUSES.map((e) => {
              return { key: e, value: e, text: e };
            })}
            onChange={handleChange("status")}
            value={contract?.status}
          />
        </div>

        <div className="two fields" style={{ marginTop: "24px" }}>
          <DropDown
            label="Candidate"
            options={candidates.map((e: ICandidate) => {
              return { key: e.id, value: e.id, text: e.name };
            })}
            onChange={handleChange("candidateId")}
            value={contract?.candidateId}
            removable={true}
          />

          <DropDown
            label="Job Opening"
            options={jobOpenings.sort((a, b) => a.clientCompany.localeCompare(b.clientCompany)).map((e: IData) => {
              return { key: e.id, value: e.id, text: `${e.clientCompany} - ${e.position} - ${e.clientName}`  };
            })}
            onChange={handleChange("jobOpeningId")}
            value={contract?.jobOpeningId}
            removable={true}
          />
        </div>

        <h4>Contributions</h4>
        <a style={{cursor: 'pointer'}} onClick={addContribution}>+ Add New Contribution</a>
        <div style={{margin: 0, maxWidth: '1200px'}} className="table-default">
          <table style={{margin: 0}}>
            <thead>
              <tr>
                <th style={{width: '120px'}}>Percentage</th>
                <th style={{width: '120px'}}>Fix Amount</th>
                <th>Payout Month</th>
                <th style={{width: '240px'}}>Contributor</th>
                <th style={{width: '240px'}}>Service</th>
                <th></th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {contract?.contributions?.map((contribution, i) => 
                <ContributionRow 
                  onChange={contributionsChange} 
                  key={`contrib_${contribution.contributionId}_${i}`} 
                  contribution={contribution} 
                  services={services}
                  contributors={contributors} />)}
            </tbody>
          </table>
        </div>

        <h4>Costs</h4>
        <a style={{cursor: 'pointer'}} onClick={addCost}>+ Add New Cost</a>
        <div style={{margin: 0, maxWidth: '1024px'}} className="table-default">
          <table style={{margin: 0}}>
            <thead>
              <tr>
                <th>Month/Yeah</th>
                <th>Amount</th>
                <th>Contributor</th>
                <th>Description</th>
                <th>File</th>
                <th></th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {contract?.costs?.filter((cost: any) => !cost.remove).map((cost, i) => 
                <CostRow 
                  onChange={costsChanged} 
                  key={`cost_${cost.costId}_${i}`} 
                  cost={cost} 
                  contributors={contributors}  
                />)}
            </tbody>
          </table>
        </div>

        <h4>Bookings</h4>
        <a style={{cursor: 'pointer'}} onClick={addBooking}>+ Add New Booking</a>
        <div style={{margin: 0, maxWidth: '800px'}} className="table-default">
          <table style={{margin: 0}}>
            <thead>
              <tr>
                <th>Month/Yeah</th>
                <th>Working Time</th>
                <th>Invoice</th>
                <th></th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {contract?.bookings?.map((booking, i) => <BookingRow onChange={bookingsChanged} key={`booking_${booking.bookingId}_${i}`} booking={booking} />)}
            </tbody>
          </table>
        </div>

        <button 
          className="ui primary button floated"
          style={{ marginBottom: "20px", marginTop: '10px' }}
          type="submit" 
          form="form" 
          value="Save">Save</button>
      </form>
      }
    </div>
  );
};

export default ContractEdit;
