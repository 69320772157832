import React, { useEffect, useState } from "react";
import "./ESGWMarginReport.scss";
import { NavTabs } from "../../../components/NavTabs/NavTabs";
import firebase from "../../../firebase";
import DropDown from "../../../components/DropDown/DropDown";
import { REPORTS_MONTHS, REPORTS_YEARS } from "../../../constants";
import CheckBox from "../../../components/Checkbox/Checkbox";
import { Box, CircularProgress } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from "react-router-dom";
import { formatValues } from "../../../components/Helpers/helpers";

const EMPTY_REPORT = {
  months: [],
  contracts: [],
  totals: []
};

const ESGWMarginReport = (props: any) => {
  const navigate = useNavigate();
  const [report, setReport] = useState({...EMPTY_REPORT});
  const [reportTotal, setReportTotal] = useState({...EMPTY_REPORT});

  const [startYear, setStartYear] = useState(new Date().getFullYear());
  const [endYear, setEndYear] = useState(new Date().getFullYear());
  const [startMonth, setStartMonth] = useState(1);
  const [endMonth, setEndMonth] = useState(12);
  const [forecast, setForecast] = useState(true);
  const [prospect, setProspect] = useState(true);
  const [extensions, setExtensions] = useState(false);
  const [loadingESGW, setLoadingESGW] = useState(true);
  const [loadingTotal, setLoadingTotal] = useState(true);
  

  const getESGWReport = async () => {
    setReport({...EMPTY_REPORT});
    const reqData: any = await firebase.getESGWReport({
      startMonth,
      startYear,
      endMonth,
      endYear
    }, forecast, prospect, extensions);

    setReport(reqData as any);
    setLoadingESGW(false);
  };

  const getESGWTotal = async () => {
    setReportTotal({...EMPTY_REPORT});
    const reqData: any = await firebase.getESGWTotal({
      startMonth,
      startYear,
      endMonth,
      endYear
    }, forecast, prospect, extensions);

    setReportTotal(reqData as any);
    setLoadingTotal(false);
  };
  

  const filter = (field: string) => (value: any) => {
    switch(field) {
      case 'startYear':
        setStartYear(value);
      break;

      case 'endYear':
        setEndYear(value);
      break;

      case 'startMonth':
        setStartMonth(value);
      break;

      case 'endMonth':
        setEndMonth(value);
      break;

      case 'forecast':
        setForecast(!forecast);
      break;

      case 'prospect':
        setProspect(!prospect);
      break;

      case 'extensions':
        setExtensions(!extensions);
      break;
    }
  };

  const getMonthCellClass = (month: any) => {
    switch(true) {
      case month?.isProspect:
        return 'prospect';
      case month?.isForecast:
        return 'forecast';
      case month?.isExtension:
        return 'extension';
    }
  };

  useEffect(() => {
    getESGWReport();
    getESGWTotal();
  }, [
    startYear,
    endYear,
    startMonth,
    endMonth,
    forecast,
    prospect,
    extensions
  ]);


  return (
    <div className="table-default margin">
      <NavTabs userData={props.userData} />
      <div style={{ minWidth: "100%", overflow: 'auto'}}>
        <a className="backBtn" onClick={() => navigate(-1)}>
            <ArrowBackIosIcon />
            Back
        </a>
        <div style={{gap: '16px', display: 'flex', marginTop: '16px', marginLeft: '10px', width: '300px'}}>
          <DropDown
            label="Start Month"
            options={REPORTS_MONTHS.map((e) => {
              return { key: e.label, value: e.value, text: e.label };
            })}
            onChange={filter('startMonth')}
            value={startMonth}
          />

          <DropDown
            label="Start Year"
            options={REPORTS_YEARS.map((e) => {
              return { key: e, value: e, text: e };
            })}
            onChange={filter('startYear')}
            value={startYear}
          />

          <DropDown
            label="End Month"
            options={REPORTS_MONTHS.map((e) => {
              return { key: e.label, value: e.value, text: e.label };
            })}
            onChange={filter('endMonth')}
            value={endMonth}
          />

          <DropDown
            label="End Year"
            options={REPORTS_YEARS.map((e) => {
              return { key: e, value: e, text: e };
            })}
            onChange={filter('endYear')}
            value={endYear}
          />

          <div className="forecastCheckbox">
            <CheckBox
              onChange={filter("forecast")}
              checked={forecast}
              option="Forecast"
              style={{marginTop: '30px'}}  
            />
          </div>

          <div className="prospectCheckbox">
            <CheckBox
              onChange={filter("prospect")}
              checked={prospect}
              option="Prospect"
              style={{marginTop: '30px'}}  
            />
          </div>
          <div className="extensionsCheckbox">
            <CheckBox
              onChange={filter("extensions")}
              checked={extensions}
              option="Extensions"
              style={{marginTop: '30px'}}  
            />
          </div>
        </div>

        <h3 style={{marginLeft: '10px'}}>ESGW</h3>
        {loadingESGW ?
        <Box sx={{ display: 'flex' }}>
          <CircularProgress sx={{margin: '24px auto'}} />
        </Box>
        :
        <table>
          <thead>
            <tr>
              <th></th>
              {report.months.map((month: any, index: number) => (
                <th key={`head_${index}`}>{month.monthName}</th>
              ))}
              <th>Total</th>
            </tr>
          </thead>

          <tbody>
            {report.contracts.map((row: any) => (
            <tr key={`contract_${row.name}`}>
              <td>{row.name}</td>
              {row.months.map((month: any, index: number) => (
                <td className={getMonthCellClass(month)} key={`month_${index}`}>{month ? month.value ? formatValues(month.value) : '-' : '-'}</td>
              ))}
              <td className="total">{formatValues(row.total)}</td>
            </tr>
            ))}

            <tr className="total">
              <td>Total</td>
              {report.totals.map((total: any, i: number) =>
                <td key={`total_${i}`}>{total > 0 ? formatValues(total) : '-'}</td>
              )}
            </tr>
          </tbody>
        </table>
        }

        <h3 style={{marginLeft: '10px'}}>Total</h3>
        {loadingTotal ?
        <Box sx={{ display: 'flex' }}>
          <CircularProgress sx={{margin: '24px auto'}} />
        </Box>
        :
        <table>
          <thead>
            <tr>
              <th></th>
              {report.months.map((month: any, index: number) => (
                <th key={`head_${index}`}>{month.monthName}</th>
              ))}
              <th>Total</th>
            </tr>
          </thead>

          <tbody>
            {reportTotal.contracts.map((row: any) => (
            <tr key={`contract_${row.name}`}>
              <td>{row.name}</td>
              {row.months.map((month: any, index: number) => (
                <td className={getMonthCellClass(month)} key={`month_${index}`}>{month ? month.value ? formatValues(month.value) : '-' : '-'}</td>
              ))}
              <td className="total">{formatValues(row.total)}</td>
            </tr>
            ))}

            <tr className="total">
              <td>Total</td>
              {reportTotal.totals.map((total: any, i: number) =>
                <td key={`total_${i}`}>{total > 0 ? formatValues(total) : '-'}</td>
              )}
            </tr>
          </tbody>
        </table>
        }
      </div>
  </div>
  );
};

export default ESGWMarginReport;
