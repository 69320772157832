import { initializeApp } from "firebase/app";
import { config } from "./config/config";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { initializeFirestore } from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { getStorage, ref } from "firebase/storage";

import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from "firebase/functions";

import { Contract, Contributor, IData, STATUS, UserData, Whitelist } from "./interfaces/interfaces";
import { v4 as uuidv4 } from "uuid";

export const firebaseApp = initializeApp(config.firebaseConfig);
const firebaseFunctions = getFunctions(firebaseApp, "europe-west6");

if (window.location.host.includes("localhost")) {
  connectFunctionsEmulator(firebaseFunctions, "localhost", 5001);
}

console.log(process.env.REACT_APP_DEBUG);
const isDebug = process.env.REACT_APP_DEBUG === "true";
// @ts-ignore
// eslint-disable-next-line no-restricted-globals
self.FIREBASE_APPCHECK_DEBUG_TOKEN = isDebug;

const appCheck = initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaV3Provider("6LcEj3IhAAAAAPiRZFfJquihdCgr6ajzIup5GsI0"),
  isTokenAutoRefreshEnabled: true,
});

export const firestoreDB = initializeFirestore(firebaseApp, {
  experimentalForceLongPolling: true,
});

export const storage = getStorage(firebaseApp, "gs://esg-requests.appspot.com");

class Firebase {
  auth: any;

  constructor() {}

  login(email: string, password: string) {
    // return this.auth.signInWithEmailAndPassword(email, password);
    const auth = getAuth();
    return signInWithEmailAndPassword(auth, email, password).then(
      (userCredential) => {
        // Signed in
        const user = userCredential.user;
        return user;
      }
    );
  }

  async logout() {
    const auth = getAuth();
    const newAuth = await signOut(auth);
    return false;
  }

  async getUser() {
    const auth = getAuth();
    const user = auth.currentUser;
    if (user !== null) {
      return user.email;
    }
  }

  async getUserData(): Promise<UserData> {
    return new Promise(async (resolve, reject) => {
      const fbGetRequest = httpsCallable(firebaseFunctions, "getUserData");
      const user = await this.getUser();
      const res = await fbGetRequest(user);
      if (res.data) {
        return resolve(res.data as UserData);
      } else {
        reject();
      }
    });
  }

  async updateRequest(requestID: string, updatedRequest: IData) {
    return new Promise(async (resolve, reject) => {
      const fbUpdateRequest = httpsCallable(firebaseFunctions, "updateRequest");
      const res = await fbUpdateRequest({ requestID, updatedRequest });
      if (res.data) {
        return resolve(res.data);
      } else {
        reject();
      }
    });
  }

  async updateCandidate(candidateID: string, updatedCandidate: any) {
    return new Promise(async (resolve, reject) => {
      const fbUpdateCandidate = httpsCallable(
        firebaseFunctions,
        "updateCandidate"
      );
      const res = await fbUpdateCandidate({ candidateID, updatedCandidate });
      if (res.data) {
        return resolve(res.data);
      } else {
        reject();
      }
    });
  }

  async updateContract(contract: Contract) {
    return new Promise(async (resolve, reject) => {
      const fbUpdateCandidate = httpsCallable(
        firebaseFunctions,
        "updateContract"
      );
      const res = await fbUpdateCandidate(contract);
      if (res.data) {
        return resolve(res.data);
      } else {
        reject();
      }
    });
  }

  async updateContributor(contributor: Contributor) {
    return new Promise(async (resolve, reject) => {
      const fbUpdateCandidate = httpsCallable(
        firebaseFunctions,
        "updateContributor"
      );
      const res = await fbUpdateCandidate(contributor);
      if (res.data) {
        return resolve(res.data);
      } else {
        reject();
      }
    });
  }

  async createRequest(requestID: string, updatedRequest: IData) {
    return new Promise(async (resolve, reject) => {
      const responsible = await this.getUser();
      const fbCreateRequest = httpsCallable(firebaseFunctions, "createRequest");
      const res = await fbCreateRequest({
        requestID,
        newRequest: {
          ...updatedRequest,
          ...(responsible && { responsible }),
          createdDate: new Date().toISOString(),
        },
      });
      if (res.data) {
        return resolve(res.data);
      } else {
        reject();
      }
    });
  }

  async deleteRequest(requestID: string) {
    return new Promise(async (resolve, reject) => {
      const fbDelRequest = httpsCallable(firebaseFunctions, "deleteRequest");
      const res = await fbDelRequest(requestID);
      if (res) {
        return resolve(res);
      } else {
        reject();
      }
    });
  }

  async deleteCandidate(candidateID: string) {
    return new Promise(async (resolve, reject) => {
      const fbDelCandidate = httpsCallable(
        firebaseFunctions,
        "deleteCandidate"
      );
      const res = await fbDelCandidate(candidateID);
      if (res) {
        return resolve(res);
      } else {
        reject();
      }
    });
  }

  async deleteWhitelist(whitelistId: string) {
    return new Promise(async (resolve, reject) => {
      const fbDelWhitelist = httpsCallable(
        firebaseFunctions,
        "deleteWhitelist"
      );
      const res = await fbDelWhitelist(whitelistId);
      if (res) {
        return resolve(res);
      } else {
        reject();
      }
    });
  }

  async duplicateRequest(oldRequest: IData) {
    const id = uuidv4();
    await this.createRequest(id, { ...oldRequest, id: id });
  }

  async getRequests(filters: STATUS[]) {
    return new Promise(async (resolve, reject) => {
      const fbGetRequest = httpsCallable(firebaseFunctions, "getRequests");
      const res = await fbGetRequest(filters);
      if (res.data) {
        return resolve(res.data);
      } else {
        reject();
      }
    });
  }

  async getRequest(requestID: string) {
    return new Promise(async (resolve, reject) => {
      const fbGetRequest = httpsCallable(firebaseFunctions, "getRequest");
      const res = await fbGetRequest(requestID);
      if (res.data) {
        return resolve(res.data);
      } else {
        reject();
      }
    });
  }

  async getCandidate(candidateID: string) {
    return new Promise(async (resolve, reject) => {
      const fbGetRequest = httpsCallable(firebaseFunctions, "getCandidate");
      const res = await fbGetRequest(candidateID);
      if (res.data) {
        return resolve(res.data);
      } else {
        reject();
      }
    });
  }

  async updateWhitelist(whitelist: Whitelist) {
    return new Promise(async (resolve, reject) => {
      const fbUpdateCandidate = httpsCallable(
        firebaseFunctions,
        "updateWhitelist"
      );
      const res = await fbUpdateCandidate(whitelist);
      if (res.data) {
        return resolve(res.data);
      } else {
        reject();
      }
    });
  }

  async getContributors(
    filters: string[]
  ) {
    return new Promise(async (resolve, reject) => {
      const fbGetRequest = httpsCallable(firebaseFunctions, "getContributors");
      const res = await fbGetRequest({filters});
      if (res.data) {
        return resolve(res.data);
      } else {
        reject();
      }
    });
  }

  async getCandidates(
    filters: string[]
  ) {
    return new Promise(async (resolve, reject) => {
      const fbGetRequest = httpsCallable(firebaseFunctions, "getCandidates");
      const res = await fbGetRequest({filters});
      if (res.data) {
        return resolve(res.data);
      } else {
        reject();
      }
    });
  }

  async getContracts(
    filters: string[]
  ) {
    return new Promise(async (resolve, reject) => {
      const fbGetRequest = httpsCallable(firebaseFunctions, "getContracts");
      const res = await fbGetRequest({filters});
      if (res.data) {
        return resolve(res.data);
      } else {
        reject();
      }
    });
  }

  async getMarginMonthlyReport(
    month: number, 
    year: number, 
    forecast: boolean
  ) {
    return new Promise(async (resolve, reject) => {
      const fbGetRequest = httpsCallable(firebaseFunctions, "getMarginMonthlyReport");
      const res = await fbGetRequest({month, year, forecast});
      if (res.data) {
        return resolve(res.data);
      } else {
        reject();
      }
    });
  }

  async getMarginReport(
    dateRange: {
      startMonth: number,
      startYear: number,
      endMonth: number,
      endYear: number
    },
    forecast: boolean,
    prospect: boolean,
    extensions: boolean,
    contributorId?: string
  ) {
    return new Promise(async (resolve, reject) => {
      const fbGetRequest = httpsCallable(firebaseFunctions, "getMarginReport");
      const res = await fbGetRequest({dateRange, forecast, prospect, extensions, contributorId});
      if (res.data) {
        return resolve(res.data);
      } else {
        reject();
      }
    });
  }

  async getESGWReport(
    dateRange: {
      startMonth: number,
      startYear: number,
      endMonth: number,
      endYear: number
    },
    forecast: boolean,
    prospect: boolean,
    extensions: boolean
  ) {
    return new Promise(async (resolve, reject) => {
      const fbGetRequest = httpsCallable(firebaseFunctions, "getESGWReport");
      const res = await fbGetRequest({dateRange, forecast, prospect, extensions});
      if (res.data) {
        return resolve(res.data);
      } else {
        reject();
      }
    });
  }

  async getESGWTotal(
    dateRange: {
      startMonth: number,
      startYear: number,
      endMonth: number,
      endYear: number
    },
    forecast: boolean,
    prospect: boolean,
    extensions: boolean
  ) {
    return new Promise(async (resolve, reject) => {
      const fbGetRequest = httpsCallable(firebaseFunctions, "getESGWTotal");
      const res = await fbGetRequest({dateRange, forecast, prospect, extensions});
      if (res.data) {
        return resolve(res.data);
      } else {
        reject();
      }
    });
  }

  async getAverageReport(
    prospect: boolean
  ) {
    return new Promise(async (resolve, reject) => {
      const fbGetRequest = httpsCallable(firebaseFunctions, "getAverageReport");
      const res = await fbGetRequest({prospect});
      if (res.data) {
        return resolve(res.data);
      } else {
        reject();
      }
    });
  }
}


export default new Firebase();
