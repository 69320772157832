import React, { useEffect, useState } from "react";
import "./App.scss";

import RequestPage from "./pages/RequestPage/RequestPage";
import LoginPage from "./pages/LoginPage/LoginPage";
import ContractPage from "./pages/ContractPage/ContractPage";
import FooterComponent from "./components/FooterComponent/FooterComponent";
import HeaderComponent from "./components/HeaderComponent/HeaderComponent";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { v4 as uuidv4 } from "uuid";
import CandidatePage from "./pages/CandidatePage/CandidatePage";
import firebase from "./firebase";
import ContractEdit from "./pages/ContractPage/Edit/ContractEdit";
import JobOpeningsPage from "./pages/JobOpeningsPage/JobOpeningsPage";
import { UserData } from "./interfaces/interfaces";
import CandidatesPage from "./pages/CandidatesPage/CandidatesPage";
import WhitelistPage from "./pages/WhitelistPage/WhitelistPage";
import WhitelistEdit from "./pages/WhitelistPage/Edit/WhitelistEdit";
import ContributorsPage from "./pages/ContributorsPage/ContributorsPage";
import ContributorEdit from "./pages/ContributorsPage/Edit/ContributorEdit";
import ReportsPage from "./pages/ReportsPage/ReportsPage";
import MarginMonthlyReport from "./pages/ReportsPage/MarginMonthlyReport/MarginMonthlyReport";
import AverageReport from "./pages/ReportsPage/AverageReport/AverageReport";
import MarginReport from "./pages/ReportsPage/MarginReport/MarginReport";
import ESGWMarginReport from "./pages/ReportsPage/ESGWMarginReport/ESGWMarginReport";


function App() {
  const [user, setUser] = useState(false); // isLoggedIn
  const [userData, setUserData] = useState<UserData | null>(null); // isLoggedIn
  const [userEmail, setUserEmail] = useState<string | null>(null); // user email  
  const [language, setLanguage] = useState("en");
  const [loaded, setLoaded] = useState(false);
  const [accessDenied, setAccessDenied] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const auth = getAuth();
    
    const authListener = auth.onAuthStateChanged(async (u) => {
      if (auth.currentUser) {
        setUser(true);
        // todo: check if email address assignment works
        setUserEmail(auth.currentUser.email);
        try {
          const userData = await firebase.getUserData();
          setUserData(userData);
        } catch(err) {
          setAccessDenied(true);
        }
      } else {
        setUser(false);
        setUserEmail(null);
      }
      setLoaded(true);
    });
  }, []);

  const logOut = async () => {
    await firebase.logout();
    setUserEmail(null);
    setUser(false);
    setUserData(null);
  };

  return (
    <div className="App">
      <div className="ui container" style={{ backgroundColor: "white" }}>
        <HeaderComponent
          isLogin={user}
          userEmail={userEmail}
          logoutFun={logOut}
          language={language}
          setLanguage={(e: React.SetStateAction<string>) => setLanguage(e)}
        />
        <div style={{ minHeight: "calc(100vh - 482px)" }}>
          {accessDenied ? <h3 style={{padding: '20px'}}>Access Denied</h3> : 
          loaded ? (
            // <Router>
            <Routes>
              <Route
                path="/"
                element={<Navigate to={`/requests/${uuidv4()}`} />}
              />
              <Route
                path="/requests/:requestID"
                element={<RequestPage userData={userData} lang={language} />}
              />
              <Route
                path="/candidates"
                element={user ? <CandidatesPage lang={language} isLogin={user} userData={userData} /> : <Navigate to="/login" replace />}
              />
              <Route
                path="/candidate"
                element={user ? <Navigate to={`/candidate/${uuidv4()}`}/> : <Navigate to="/login" replace />}
              />
              <Route
                path="/candidate/:candidateID"
                element={user ? <CandidatePage lang={language} isLogin={user} userData={userData} /> : <Navigate to="/login" replace />}
              />
              <Route
                path="/contracts"
                element={user ? <ContractPage lang={language} isLogin={user} userData={userData} /> : <Navigate to="/login" replace />}
              />
              <Route
                path="/contracts/:contractId"
                element={user ? <ContractEdit lang={language} isLogin={user} /> : <Navigate to="/login" replace />}
              />
              <Route
                path="/contributors"
                element={user ? <ContributorsPage lang={language} isLogin={user} userData={userData} /> : <Navigate to="/login" replace />}
              />
              <Route
                path="/contributors/:contributorId"
                element={user ? <ContributorEdit lang={language} isLogin={user} /> : <Navigate to="/login" replace />}
              />
              <Route
                path="/admin"
                element={
                  user ? <Navigate to="/reports" replace /> : <Navigate to="/login" replace />
                }
              />
              <Route
                path="/job_openings"
                element={
                  user ? <JobOpeningsPage userData={userData} isLogin={user} /> : <Navigate to="/login" replace />
                }
              />

              <Route
                path="/whitelist"
                element={
                  user ? <WhitelistPage userData={userData} isLogin={user} /> : <Navigate to="/login" replace />
                }
              />

              <Route
                path="/reports/margin-monthly"
                element={
                  user ? <MarginMonthlyReport userData={userData} isLogin={user} /> : <Navigate to="/login" replace />
                }
              />

              <Route
                path="/reports/margin"
                element={
                  user ? <MarginReport userData={userData} isLogin={user} /> : <Navigate to="/login" replace />
                }
              />

              {userData?.role === 'operator' ?
                <>
                  <Route
                    path="/reports/average"
                    element={
                      user ? <AverageReport userData={userData} isLogin={user} /> : <Navigate to="/login" replace />
                    }
                  />
                  <Route
                    path="/reports/esgw"
                    element={
                      user ? <ESGWMarginReport userData={userData} isLogin={user} /> : <Navigate to="/login" replace />
                    }
                  />
                </>
              : 
              <Route
                path="/reports/esgw"
                element={
                  user ? <Navigate to="/reports" replace /> : <Navigate to="/login" replace />
                }
              />}

              <Route
                path="/reports"
                element={
                  user ? <ReportsPage userData={userData} isLogin={user} /> : <Navigate to="/login" replace />
                }
              />

              <Route
                path="/whitelist/:whitelistId"
                element={
                  user ? <WhitelistEdit userData={userData} isLogin={user} /> : <Navigate to="/login" replace />
                }
              />
              <Route
                path="/login"
                element={
                  user ? <Navigate to="/reports" replace /> : <LoginPage />
                }
              />
              {/* <Route
                path="*"
                element={<Navigate to={`/requests/${uuidv4()}`} />}
              /> */}
            </Routes>
          ) : // </Router>
          null}
        </div>
        {!user ?
        <FooterComponent language={language} />
        : null}
      </div>
    </div>
  );
}

export default App;
