import { CONTRIBUTOR_TYPE } from "../constants";

export interface IData extends Record<string, any> {
  position: string;
  seniority: Seniority;
  remarks: string;
  languageDE: string;
  languageEN: string;
  tasks: string;
  requirements: string;
  location: Location;
  active: boolean;
  id: string;
  startDate: StartDate;
  endDate: EndDate;
  pensum: Pensum;
  chargeRate: string;
  candidates: Array<String>;
  candidatesData?: Array<object>;
}

export interface Pensum {
  p100: boolean;
  p80: boolean;
  other: boolean;
  otherValue: string;
}

export interface Seniority {
  professional: boolean;
  senior: boolean;
  expert: boolean;
  seniorExpert: boolean;
  other: boolean;
  otherValue: string;
}

export interface StartDate {
  asap: boolean;
  m1: boolean;
  m3: boolean;
  other: boolean;
  otherValue: string;
}

export interface EndDate {
  m6: boolean;
  m12: boolean;
  y2: boolean;
  other: boolean;
  otherValue: string;
}

export interface Location {
  homeOffice100: boolean;
  homeOffice75: boolean;
  homeOffice25: boolean;
  homeOffice0: boolean;
  nearShoring: boolean;
  other: boolean;
  otherValue: string;
}

export interface Errors {
  position?: string;
  clientName?: string;
  clientEmailOrPhone?: string;
  clientCompany?: string;
  clientMail?: string;
}

export interface Contract {
  contractId: string;
  name: string;
  companyName: string;
  startDate: string;
  endDate: string;
  status: STATUS,
  chargeRate: string;
  hoursPerDay: number;
  remarks: string;
  payRate: string;
  closingMonth: number;
  workload: number;
  candidateId: string;
  jobOpeningId: string;
  bookingIds: string[];
  contributionIds: string[];
  createdDate?: string;
  probability: number;
  probabilityExtension: number;
  bookings: Booking[],
  contributions: Contribution[];
  costs: Cost[];
}

export enum STATUS {
  PROSPECT = 'Prospect',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  ARCHIVE = 'Archive'
}

export interface UserData {
  email: string;
  role: string;
}

export interface Whitelist {
  whitelistId: string;
  companyName: string;
  category: string;
  remark: string;
  createdDate?: string;
}

export interface FileDTO {
  name: string;
  base64?: string;
  type: string;
  fullPath?: string;
  delete?: boolean;
  uploading?: boolean;
}

export interface Contributor {
  contributorId: string;
  serviceIds : string[];
  whitelistIds: string[];
  phone: string;
  status: STATUS;
  name: string;
  files: FileDTO[];
  type: CONTRIBUTOR_TYPE[];
  email: string;
  notification: string;
  createdDate?: string;
}

export interface Service {
  serviceId: string;
  type: string;
}

export interface Booking {
  bookingId: string;
  invoice?: FileDTO;
  workingTime?: number;
  monthYear: {
    month: number;
    year: number;
  }
  editing?: boolean;
}

export interface Cost {
  costId: string;
  file?: FileDTO;
  contributorId?: string;
  description?: string;
  amount?: number;
  monthYear: {
    month: number;
    year: number;
  }
  editing?: boolean;
  remove?: boolean;
}

export interface Contribution {
  contributionId: string;
  percentage?: number;
  fixAmount?: number;
  contributorId?: string;
  serviceId?: string;
  editing?: boolean;
  payoutMonth?: {
    month?: number;
    year?: number;
    description?: string;
  }
}