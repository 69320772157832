import Grid from "@mui/material/Grid";
import React from "react";
import { language } from "../../language";
import './FooterComponent.scss';
import esgwf from "../../assets/esg-wf-dark.svg";
// import pic from "../../assets/picture.png";
import pic from "../../assets/armin.png";

export interface FooterComponentProps {
  language: LanguageProp;
}

export interface LanguageProp {
  // any
}


const FooterComponent = (props: FooterComponentProps) => {
  return (<div className="footer-component">
    <div className="blue-line" />

    <div style={{ 
      height: "100%",
      marginLeft: "160px", 
      marginRight: "160px"
       }}>
      <Grid container spacing={0}
        style={{
          height: "calc(100% - 8px)"
        }}>

        <Grid item xs={6} style={{
          // border: "1px solid red"
        }}>
          <img src={esgwf} style={{ marginTop: "40px" }} />
          <p style={{ paddingRight: "20px", marginTop: "40px" }}>{
            language.footerLeft["de"]
          }</p>
        </Grid>

        <Grid item xs={4} style={{
          // border: "1px solid red",
        }}>
          <div style={{
            borderLeft: "1px solid rgba(255, 255, 255, 0.2)",
            paddingLeft: "32px",
            marginTop: "80px"
          }}>
            {/* <img src={pic}
              style={{
                borderRadius: "50%",
                border: "2px solid rgba(255, 255, 255, 0.38)",
                height: "46px",
                backgroundColor: "rgba(255, 255, 255, 0.4)"
              }} />

            <h3>
              {language.footerRightName}
            </h3>
            <h4>
              {language.footerRight}
            </h4> */}
          </div>
        </Grid>

        <Grid item xs={2} style={{
          // border: "1px solid red",
        }}>
          <h3 style={{
            marginTop: "96px"
          }}>
            {language.footerRightAddressName}
          </h3>
          <h4>
            {language.footerRightAddress}
          </h4>
        </Grid>

      </Grid>
    </div>
  </div>)
}

export default FooterComponent;