import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import "./Dropzone.scss";

const Dropzone = (props: any) => {
  const onDrop = useCallback((acceptedFiles: any[]) => {
    const files: {
      name: any;
      base64: string | ArrayBuffer | null;
      type: any;
    }[] = [];
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onabort = () => console.info("file reading was aborted");
      reader.onerror = () => console.error("file reading has failed");
      reader.onload = (fileLoadedEvent) => {
        if (fileLoadedEvent && fileLoadedEvent.target) {
          const base64 = fileLoadedEvent.target.result;
          // props.onDataChange(base64);
          props.onDataChange({
            name: file.name,
            base64: base64,
            type: file.type,
          });
        }
      };
      const type0 = file["type"].split("/")[0];
      const type1 = file["type"].split("/")[1];

      reader.readAsDataURL(file);
    });
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className="dropzonecontainer">
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the file here ...</p>
        ) : (
          <>
            <i className="icon file image outline" />
            <p>{props.label}</p>
          </>
        )}
      </div>
    </div>
  );
};
export default Dropzone;
