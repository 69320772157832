import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React from "react";
import { IData } from "../../interfaces/interfaces";
import { ICandidate } from "../CandidatePage/CandidatePage";

const CandidateDialog = (props: any) => {
  const getTitle = () => {
    const t = "Select Candidates for Request";
    return props.request ? t + `: ${props.request.number}` : t;
  };
  const [selected, setSelected] = React.useState<string[]>(
    props.request.candidates ? props.request.candidates : []
  );

  const handleChange = (event: SelectChangeEvent<typeof selected>) => {
    const {
      target: { value },
    } = event;
    setSelected(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const close = () => {
    props.handleClose();
    props.setSelected(selected);
    setSelected([]);
  };

  return (
    <Dialog disableEscapeKeyDown open={props.isOpen} onClose={() => close}>
      <DialogTitle>{getTitle()}</DialogTitle>
      <DialogContent>
        <Box component="form" sx={{ display: "flex", flexWrap: "wrap" }}>
          <FormControl sx={{ m: 1, width: 300 }}>
            <InputLabel id="demo-multiple-name-label">Name</InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              multiple
              // value={props.request.candidates ? props.request.candidates : []}
              value={selected}
              onChange={handleChange}
              input={<OutlinedInput label="Name" />}
              // MenuProps={MenuProps}
            >
              {props.candidates.map((cand: ICandidate) => (
                <MenuItem
                  key={cand.id}
                  value={cand.id}
                  // style={getStyles(name, personName, theme)}
                >
                  {cand.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CandidateDialog;
