import React, { useEffect, useState } from "react";
import "./MarginReport.scss";
import { NavTabs } from "../../../components/NavTabs/NavTabs";
import firebase, { firestoreDB } from "../../../firebase";
import DropDown from "../../../components/DropDown/DropDown";
import { REPORTS_MONTHS, REPORTS_YEARS } from "../../../constants";
import CheckBox from "../../../components/Checkbox/Checkbox";
import { Box, CircularProgress } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from "react-router-dom";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { Contributor, STATUS } from "../../../interfaces/interfaces";
import { formatValues } from "../../../components/Helpers/helpers";


const EMPTY_REPORT = {
  months: [],
  contracts: [],
  totals: []
};

const MarginReport = (props: any) => {
  const navigate = useNavigate();
  const [report, setReport] = useState({...EMPTY_REPORT});
  const [contributors, setContributors] = useState<Contributor[]>([]);
  const [contributor, setContributor] = useState<string>(props.userData.contributor?.contributorId);
  const [startYear, setStartYear] = useState(new Date().getFullYear());
  const [endYear, setEndYear] = useState(new Date().getFullYear());
  const [startMonth, setStartMonth] = useState(1);
  const [endMonth, setEndMonth] = useState(12);
  const [forecast, setForecast] = useState(true);
  const [prospect, setProspect] = useState(false);
  const [extensions, setExtensions] = useState(false);

  const [loading, setLoading] = useState(true);

  const getMarginReport = async () => {
    if (!contributor) return;
    setReport({...EMPTY_REPORT});
    const reqData: any = await firebase.getMarginReport({
      startMonth,
      startYear,
      endMonth,
      endYear
    }, forecast, prospect, extensions, contributor);

    setReport(reqData as any);
    setLoading(false);
  };

  const fetchContributors = () => {
    const q = query(collection(firestoreDB, "contributor"), where("status", "==", STATUS.ACTIVE));
    const unsubscribe = onSnapshot(q, (querySnapshot: any) => {
      const res: Array<object> = [];
      
      querySnapshot.forEach((doc: any) => {
        res.push(doc.data());
      });
      if (res.length > 0 && !contributor) {
        const first = res[0] as Contributor;
        setContributor(first.contributorId);
      }
      setContributors(res as Contributor[]);
    });
  };

  const filter = (field: string) => (value: any) => {
    switch(field) {
      case 'startYear':
        setStartYear(value);
      break;

      case 'endYear':
        setEndYear(value);
      break;

      case 'startMonth':
        setStartMonth(value);
      break;

      case 'endMonth':
        setEndMonth(value);
      break;

      case 'contributor':
        setContributor(value);
      break;

      case 'forecast':
        setForecast(!forecast);
      break;

      case 'prospect':
        setProspect(!prospect);
      break;
      
      case 'extensions':
        setExtensions(!extensions);
      break;
    }
  };

  const getMonthCellClass = (month: any) => {
    switch(true) {
      case month?.isProspect:
        return 'prospect';
      case month?.isForecast:
        return 'forecast';
      case month?.isExtension:
        return 'extension';
    }
  };

  useEffect(() => {
    if (props.userData.role === 'operator') {
      fetchContributors();
    }
    getMarginReport();
  }, [
    contributor,
    startYear,
    endYear,
    startMonth,
    endMonth,
    forecast,
    prospect,
    extensions
  ]);

  return (
    <div className="table-default margin">
      <NavTabs userData={props.userData} />
      <div style={{ width: "100%", overflowX: 'auto'}}>
        <a className="backBtn" onClick={() => navigate(-1)}>
            <ArrowBackIosIcon />
            Back
        </a>
        <div style={{gap: '16px', display: 'flex', marginTop: '16px', marginLeft: '10px', width: '300px'}}>
          {props.userData.role === 'operator' ?
          <div style={{minWidth: '220px'}}>
            <DropDown
              label="Contributor"
              options={contributors.map((e) => {
                return { key: e.contributorId, value: e.contributorId, text: e.name };
              })}
              onChange={filter("contributor")}
              value={contributor}
            />
          </div>
          : null}
          <DropDown
            label="Start Month"
            options={REPORTS_MONTHS.map((e) => {
              return { key: e.label, value: e.value, text: e.label };
            })}
            onChange={filter('startMonth')}
            value={startMonth}
          />

          <DropDown
            label="Start Year"
            options={REPORTS_YEARS.map((e) => {
              return { key: e, value: e, text: e };
            })}
            onChange={filter('startYear')}
            value={startYear}
          />

          <DropDown
            label="End Month"
            options={REPORTS_MONTHS.map((e) => {
              return { key: e.label, value: e.value, text: e.label };
            })}
            onChange={filter('endMonth')}
            value={endMonth}
          />

          <DropDown
            label="End Year"
            options={REPORTS_YEARS.map((e) => {
              return { key: e, value: e, text: e };
            })}
            onChange={filter('endYear')}
            value={endYear}
          />

          <div className="forecastCheckbox">
            <CheckBox
              onChange={filter("forecast")}
              checked={forecast}
              option="Forecast"
              style={{marginTop: '30px'}}  
            />
          </div>

          <div className="prospectCheckbox">
            <CheckBox
              onChange={filter("prospect")}
              checked={prospect}
              option="Prospect"
              style={{marginTop: '30px'}}  
            />
          </div>

          <div className="extensionsCheckbox">
            <CheckBox
              onChange={filter("extensions")}
              checked={extensions}
              option="Extensions"
              style={{marginTop: '30px'}}  
            />
          </div>
        </div>
        {loading ?
        <Box sx={{ display: 'flex' }}>
          <CircularProgress sx={{margin: '24px auto'}} />
        </Box>
        :
        <table>
          <thead>
            <tr>
              <th></th>
              {report.months.map((month: any, index: number) => (
                <th key={`head_${index}`}>{month.monthName}</th>
              ))}
              <th>Total</th>
            </tr>
          </thead>

          <tbody>
            {report.contracts.map((row: any) => (
            <tr key={`contract_${row.name}`}>
              <td>{row.name}</td>
              {row.months.map((month: any, index: number) => (
                <td className={getMonthCellClass(month)} key={`month_${index}`}>{month ? month.value ? formatValues(month.value) : '-' : '-'}</td>
              ))}
              <td className="total">{formatValues(row.total)}</td>
            </tr>
            ))}

            <tr className="total">
              <td>Total</td>
              {report.totals.map((total: any, i: number) =>
                <td key={`total_${i}`}>{total > 0 ? formatValues(total) : '-'}</td>
              )}
            </tr>
          </tbody>
        </table>
        }
      </div>
  </div>
  );
};

export default MarginReport;
