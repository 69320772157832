import React, { useEffect, useState } from "react";
import "./AverageReport.scss";
import { NavTabs } from "../../../components/NavTabs/NavTabs";
import firebase from "../../../firebase";
import { Box, CircularProgress } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from "react-router-dom";
import CheckBox from "../../../components/Checkbox/Checkbox";
import { formatValues } from "../../../components/Helpers/helpers";

const EMPTY_REPORT = {
  contracts: [],
  summary: {
    marginPercentage: 0,
    hours: 0,
    costs: 0,
    margin: 0,
    revenue: 0,
    contributors: []
  }
} as any;

const AverageReport = (props: any) => {
    const navigate = useNavigate();
    const [report, setReport] = useState({...EMPTY_REPORT});
    const [loading, setLoading] = useState(true);
    const [prospect, setProspect] = useState(true);


    const getAverageReport = async () => {
        setReport({...EMPTY_REPORT});
        const reqData: any = await firebase.getAverageReport(prospect);
        setReport(reqData);
        setLoading(false);
    };
    
    useEffect(() => {
        getAverageReport();
    }, [prospect]);

    const filter = (field: string) => (value: any) => {
        setProspect(!prospect);
    };

    return (
        <div className="table-default">
        <NavTabs userData={props.userData} />
        <div style={{ width: "100%"}}>
            <a className="backBtn" onClick={() => navigate(-1)}>
                <ArrowBackIosIcon />
                Back
            </a>
            <div style={{gap: '16px', display: 'flex', marginTop: '16px', marginLeft: '10px', width: '300px'}}>
            <div className="prospectCheckbox">
                <CheckBox
                    onChange={filter("prospect")}
                    checked={prospect}
                    option="Prospect"
                    style={{marginTop: '30px'}}  
                />
            </div>
            </div>
            {loading ?
            <Box sx={{ display: 'flex' }}>
            <CircularProgress sx={{margin: '24px auto'}} />
            </Box>
            :
            <table>
            <thead>
                <tr>
                    <th>Contract</th>
                    <th>Average of Margin %</th>
                    <th>Average of Contract period (months)</th>
                    <th>Average Revenue (per month)</th>
                    <th>Average Cost (per month)</th>
                    <th>Average Margin (per month)</th>
                </tr>
            </thead>

            <tbody>
                {report.contracts.map((contract: any) => (
                    <tr className={contract.isProspect ? 'prospect' : ''}>
                        <td>{contract.name}</td>
                        <td>{contract.averageMarginPercent.toFixed(2)}%</td>
                        <td>{formatValues(contract.averagePeriod)}</td>
                        <td>{formatValues(contract.averageRevenue)}</td>
                        <td>{formatValues(contract.averageCost)}</td>
                        <td>{formatValues(contract.averageMargin)}</td>
                    </tr>
                ))}
                <tr className="total-row">
                    <td>Total</td>
                    <td>{report.summary.averageMarginPercent?.toFixed(2)}%</td>
                    <td>{formatValues(report.summary.averagePeriod)}</td>
                    <td>{formatValues(report.summary.averageRevenue)}</td>
                    <td>{formatValues(report.summary.averageCost)}</td>
                    <td>{formatValues(report.summary.averageMargin)}</td>
                </tr>
            </tbody>
            </table>
            }
        </div>
    </div>
    );
};

export default AverageReport;
