import React from 'react';
import {Checkbox, Form} from 'semantic-ui-react';


interface CheckboxProps {
  label?: string
  option: string,
  checked: boolean,
  onChange: (value: boolean) => void,
  error?: any,
  style?: any,
  fieldStyle?: any,
}

const CheckBox = ({label, option, checked, onChange, error, style, fieldStyle}: CheckboxProps) => {
  const toggle = () => {
    onChange(!checked);
  };

  const getLabel = () => {
    if (!label) {
      return null;
    }
    if (error) {
      return <label style={{color: 'red'}}>{label}</label>
    } else {
      return <label>{label}</label>;
    }
  }

  return (
      <div className="field" style={fieldStyle}>
        {getLabel()}
        <Form>
          <Checkbox
              className="drop"
              label={<label style={{whiteSpace: "nowrap"}}>{option}</label>}
              onChange={toggle}
              checked={checked}
              style={style}
          />
        </Form>
      </div>
  );
};
export default CheckBox;
