import React from "react";

const TextField = (props: any) => {

  const set = (e: any) => {
    props.onChange(e.target.value);
  };

  return (
      <div className="field" style={props.fieldStyle}>
        {props.error ? (
            <label style={{color: 'red'}}>{props.label}</label>
        ) : (
            <label>{props.label}</label>
        )}
        {props.rows ? (
            <textarea
                key={props.label}
                type={props.type || 'text'}
                value={props.value || ''}
                placeholder={props.placeholder}
                onChange={set}
                rows={props.rows}
                required={props.required}
                {...props.rest}
            />
        ) : (
            <input
                key={props.label}
                type={props.type || 'text'}
                value={props.value || ''}
                placeholder={props.placeholder}
                onChange={set}
                disabled={props.disabled}
                style={props.style}
                required={props.required}
                {...props.rest}
            />
        )}
      </div>
  );
}

export default TextField;