import React, { useEffect, useState } from "react";
import "./ReportsPage.scss";
import { NavTabs } from "../../components/NavTabs/NavTabs";
import { useNavigate } from "react-router-dom";
import { BlueButton } from "../../components/Button/Button";

const ReportsPage = (props: any) => {
  const navigate = useNavigate();

  return (
    <div className="table-default">
      <NavTabs userData={props.userData} />
      <div style={{ width: "100%", display: 'flex' }}>
      <table>
        <thead>
          <tr>
            <th>Report</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              Margin Report - Monthly Report
            </td>
            <td>
              <BlueButton onClick={() => navigate('margin-monthly')} label="View details" />
            </td>
          </tr>

          <tr>
            <td>
              Margin Report
            </td>
            
            <td>
              <BlueButton onClick={() => navigate('margin')} label="View details" />
            </td>
          </tr>

          {props.userData?.role === 'operator' ?
          <>
            <tr>
              <td>
                ESGW Margin Report
              </td>
              
              <td>
                <BlueButton onClick={() => navigate('esgw')} label="View details" />
              </td>
            </tr>

            <tr>
              <td>
                Average Report
              </td>
              
              <td>
                <BlueButton onClick={() => navigate('average')} label="View details" />
              </td>
            </tr>
          </>
          : null}
        </tbody>
      </table>
    </div>
  </div>
  );
};

export default ReportsPage;
