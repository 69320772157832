import { Tab, Tabs } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { ROLES } from "../../constants";
import { UserData } from "../../interfaces/interfaces";
import "./NavTabs.scss";

interface TabsProps {
    userData: UserData;
    [x:string]: any;
}
export const NavTabs = ({ ...props }: TabsProps) => {
    if (!props.userData) {
        return null;
    }

    return (
        props.userData.role === ROLES.OPERATOR ? <OperatorTabs /> : <ContributorTabs />
    );
  };

const OperatorTabs = () => {
    const location = useLocation();
    const navigate = useNavigate();

    return (
        <div className="navtabs-container">
            <Tabs value={location.pathname}>
                <Tab onClick={() => navigate('/contracts')} value={'/contracts'} label="Contracts" />
                <Tab onClick={() => navigate('/job_openings')} value={'/job_openings'} label="Job Openings" />
                <Tab onClick={() => navigate('/candidates')} value={'/candidates'} label="Candidates" />
                <Tab onClick={() => navigate('/contributors')} value={'/contributors'} label="Contributors" />
                {/* <Tab onClick={() => navigate('/whitelist')} value={'/whitelist'} label="White List" /> */}
                <Tab onClick={() => navigate('/reports')} value={'/reports'} label="Reports" />

                {/* hidden */}
                <Tab style={{display: 'none'}} onClick={() => navigate('/reports/margin-monthly')} value={'/reports/margin-monthly'} label="Reports" />
                <Tab style={{display: 'none'}} onClick={() => navigate('/reports/margin')} value={'/reports/margin'} label="Reports" />
                <Tab style={{display: 'none'}} onClick={() => navigate('/reports/average')} value={'/reports/average'} label="Reports" />
                <Tab style={{display: 'none'}} onClick={() => navigate('/reports/esgw')} value={'/reports/esgw'} label="Reports" />
            </Tabs>
        </div>
    )
};

const ContributorTabs = () => {
    const location = useLocation();
    const navigate = useNavigate();

    return (
        <div className="navtabs-container">
            <Tabs value={location.pathname}>
                <Tab onClick={() => navigate('/job_openings')} value={'/job_openings'} label="Job Openings" />
                <Tab onClick={() => navigate('/candidates')} value={'/candidates'} label="Candidates" />
                {/* <Tab onClick={() => navigate('/whitelist')} value={'/whitelist'} label="White List" /> */}
                <Tab onClick={() => navigate('/reports')} value={'/reports'} label="Reports" />

                {/* hidden */}
                <Tab style={{display: 'none'}} onClick={() => navigate('/reports/margin-monthly')} value={'/reports/margin-monthly'} label="Reports" />
                <Tab style={{display: 'none'}} onClick={() => navigate('/reports/margin')} value={'/reports/margin'} label="Reports" />
            </Tabs>
        </div>
    )
};
