import React, { useEffect, useState } from "react";
import "./ContributorsPage.scss";
import firebase, { firestoreDB } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { BlueButton } from "../../components/Button/Button";
import { Contributor, STATUS } from "../../interfaces/interfaces";
import { v4 as uuidv4 } from "uuid";
import DropDown from "../../components/DropDown/DropDown";
import { COMMON_STATUSES, CONTRIBUTORS_FILTER_KEY } from "../../constants";
import { NavTabs } from "../../components/NavTabs/NavTabs";
import { Box, Chip, CircularProgress } from "@mui/material";
import { collection, onSnapshot, query } from "firebase/firestore";

const ContributorsPage = (props: any) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(Boolean);
  const [contributors, setContributors] = useState(Array<Contributor>);
  const [services, setServices] = useState({});
  const savedFilters = localStorage.getItem(CONTRIBUTORS_FILTER_KEY);
  const [statusFilter, setStatusFilter] = useState(savedFilters ? JSON.parse(savedFilters) : [STATUS.ACTIVE, STATUS.PROSPECT]);

  const fetchContributors = async (filters: any) => {
    setLoading(true);
    localStorage.setItem(CONTRIBUTORS_FILTER_KEY, JSON.stringify(filters));
    setContributors(await firebase.getContributors(filters) as Contributor[]);
    setLoading(false);
  };

  const fetchServices = () => {
    const q = query(collection(firestoreDB, "services"));
    const unsubscribe = onSnapshot(q, (querySnapshot: any) => {

      const res: any = {};
      
      querySnapshot.forEach((doc: any) => {
        const data = doc.data();
        res[data.serviceId] = data;
      });
      setServices(res);
    });
  };

  const filter = (data: any) => {
    setStatusFilter(data);
    fetchContributors(data);
  };

  useEffect(() => {
    fetchContributors(statusFilter);
    fetchServices();
  }, []);

  if (!props.isLogin) {
    navigate('/login');
  }

  if (props.userData.role !== 'operator') {
    navigate('/reports');
  }

  return (
    <div className="table-default">
      <NavTabs userData={props.userData} />
      <div style={{ width: "100%", display: 'flex' }}>

        <BlueButton
          label="New Contributor"
          onClick={() => navigate("/contributors/" + uuidv4())}
          style={{ marginBottom: "20px" }}
        />

        <div
          style={{marginLeft: 'auto', marginTop: '-14px'}}>
          <DropDown
            label="Status"
            options={COMMON_STATUSES.map((e) => {
              return { key: e, value: e, text: e };
            })}
            onChange={filter}
            value={statusFilter}
            multiple={true}
          />
        </div>
      </div>

      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Phone Number</th>
            <th>Types</th>
            <th>Status</th>
          </tr>
        </thead>

        <tbody>
          {contributors.map((contributor, i) => <ContributorRow key={`row_${i}`} contributor={contributor} services={services} />)}
        </tbody>
      </table>
      {loading ?
      <Box sx={{ display: 'flex' }}>
        <CircularProgress sx={{margin: '24px auto'}} />
      </Box>
      : <></>}
    </div>
  );
};

const ContributorRow = (props: {contributor: Contributor, services: any}) => {
  const navigate = useNavigate();
  return (
    <tr>
      <td>
        <a onClick={() => navigate('/contributors/'+ props.contributor.contributorId)}>
          {props.contributor.name}
        </a>
      </td>
      <td>{props.contributor.email ?? '-'}</td>
      <td>{props.contributor.phone ?? '-'}</td>
      <td>{props.contributor.serviceIds ? props.contributor.serviceIds.map(id => props.services[id].type).join(', ') : '-'}</td>
      <td>
        {props.contributor.status ?
          <Chip className={`status_chip status_chip_${props.contributor.status.toLowerCase()}`} label={props.contributor.status} color="success" variant="outlined" />
        : '-'}
      </td>
    </tr>
  );
};

export default ContributorsPage;
