import React from "react";
import {Checkbox, Form} from "semantic-ui-react";

const CheckboxTextField = (props: any) => {

  const set = (e: any) => {
    props.onChange(e.target.value);
  };

  const toggle = () => {
    props.onChangeToggle(!props.checked);
  };

  const textStyle = {
    paddingLeft: "0",
    marginLeft: "0",
    borderLeft: "0",
    borderRight: "0",
    borderTop: "0",
    borderRadius: 0,
    borderBottom: "1px solid black",
    width: "15%"
  }

  const fieldStyle = {
    display: "contents",
  }

  return (
      <div className="field" style={fieldStyle}>
        <Form>
          <Checkbox
              className="drop"
              label={<label style={{whiteSpace: "nowrap"}}>{props.option}</label>}
              onChange={toggle}
              checked={props.checked}
              style={props.checkboxStyle}
          />
        </Form>
        <input
            key={props.label}
            type="text"
            value={props.value || ''}
            placeholder={props.placeholder}
            onChange={set}
            disabled={props.disabled}
            style={textStyle}
            variant="standard"
            {...props.rest}
        />
      </div>
  );
}

export default CheckboxTextField;