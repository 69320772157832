import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BlueButton } from "../../components/Button/Button";
import Checkbox from "../../components/Checkbox/Checkbox";
import RadioButtons from "../../components/RadioButtons/RadioButtons";
import TextField from "../../components/TextField/TextField";
import firebase, { firestoreDB } from "../../firebase";
import "./RequestPage.scss";
import _ from "lodash";
import { language } from "../../language";
import CheckboxTextField from "../../components/CheckboxTextField/CheckboxTextField";
import { validateMail } from "../../util/emailValidator";
import { ICandidate, IDataType } from "../CandidatePage/CandidatePage";
import SelectCandidateForm from "./SelectCandidateForm";
import SuccessModal from "../../components/SuccessModal/SuccessModal";
import Dropzone from "../../components/Dropzone/Dropzone";
import { Box, CircularProgress, Tooltip } from "@mui/material";
import trashSVG from "../../assets/trash.svg";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { getFileShown } from "../../util/fileUtil";
import { Contributor, Errors, IData } from "../../interfaces/interfaces";
import DropDown from "../../components/DropDown/DropDown";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { COMMON_STATUSES, ROLES } from "../../constants";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const initState: IData = {
  position: "",
  seniority: {
    professional: false,
    senior: false,
    expert: false,
    seniorExpert: false,
    other: false,
    otherValue: "",
  },
  remarks: "",
  languageDE: "",
  languageEN: "",
  tasks: "",
  requirements: "",
  location: {
    homeOffice100: false,
    homeOffice0: false,
    homeOffice25: false,
    homeOffice75: false,
    nearShoring: false,
    other: false,
    otherValue: "",
  },
  active: true,
  id: "",
  startDate: {
    asap: false,
    m1: false,
    m3: false,
    other: false,
    otherValue: "",
  },
  endDate: {
    m12: false,
    m6: false,
    y2: false,
    other: false,
    otherValue: "",
  },
  pensum: {
    p100: false,
    p80: false,
    other: false,
    otherValue: "",
  },
  chargeRate: "",
  candidates: [],
  files: [],
};

type IDataOptions = keyof typeof initState;

const RequestPage = ({ userData, lang }: any) => {
  const params = useParams();
  const navigate = useNavigate();

  const handleChange = (field: any) => (value: any) => {
    setData({ ...data, [field]: value });
  };

  const handleCheckboxChange =
    (fieldFamily: IDataOptions, field: string) => (value: boolean | string) => {
      const t = data[fieldFamily];
      t[field] = value;
      setData({ ...data, [fieldFamily]: t });
    };

  const initStateCopy = {...initState};
  if (userData?.role === 'contributor' && userData?.contributor.contributorId) {
    initStateCopy.contributorId = userData?.contributor.contributorId;
  }

  const [loading, setLoading] = useState(Boolean);
  const [data, setData] = useState(initStateCopy);
  const [candidates, setCandidates] = useState<ICandidate[]>([]);
  const [newRequest, setNewRequest] = useState(false);
  const [errs, setErrs] = useState<Errors>({});
  const [contributors, setContributors] = useState<Contributor[]>([]);

  // modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleUpdate = (isNew: boolean) => {
    if (params.requestID) {
      if (validateInput()) {
        if (isNew) {
          firebase.createRequest(params.requestID, data);
        } else {
          firebase.updateRequest(params.requestID, data);
        }
        handleOpen();
      }
    } else {
      console.error("no id yet");
    }
  };

  const validateInput = () => {
    const e: Errors = {};
    if (data.clientEmail && !validateMail(data.clientEmail)) {
      e.clientMail = "Email not correct";
    }
    if (!data.position){
      e.position = "Position name missing";
    }
    if (!data.clientName) {
      e.clientName = "Name missing";
    }
    if (!data.clientCompany) {
      e.clientCompany = "Company missing";
    }
    if (!(data.clientEmail || data.clientPhone)) {
      e.clientEmailOrPhone = "Email or Phone missing";
    }
    if (_.isEmpty(e)) {
      setErrs({});
      return true;
    } else {
      setErrs(e);
      return false;
    }
  };

  const fetch = async (requestID: string) => {
    setLoading(true);
    const reqData: any = await firebase.getRequest(requestID);
    let f: object;
    if (reqData.id) {
      setNewRequest(false);
      f = reqData;
    } else {
      f = data;
      setNewRequest(true);
    }
    const t: IData = f as IData;
    setData({ ...t, id: requestID });
    const candidatesData: Array<ICandidate> =
      t.candidatesData as Array<ICandidate>;
    setCandidates(candidatesData);
    setLoading(false);
  };

  const fetchContributors = () => {
    const q = query(collection(firestoreDB, "contributor"), where("status",  "==", "Active"));
    const unsubscribe = onSnapshot(q, (querySnapshot: any) => {
      const res: Array<object> = [];
      
      querySnapshot.forEach((doc: any) => {
        res.push(doc.data());
      });
      setContributors(res as Contributor[]);
    });
  };

  useEffect(() => {
    fetch(params.requestID!);
    if (userData?.role === ROLES.OPERATOR) {
      fetchContributors();
    }
  }, [params.requestID]);

  const onDataChange = (file: any) => {
    setData((prevState) => {
      const files = prevState.files || [];
      files.push(file);
      return { ...prevState, files };
    });
  };

  const deleteFile = (file: IDataType) => {
    const files = data.files;
    var index = files.indexOf(file);
    if (index !== -1) {
      files.splice(index, 1);
    }
    setData({ ...data, files });
  };

  return (
    <div className="form-page">
      {loading ?
        <Box sx={{ display: 'flex', width: '100%' }}>
          <CircularProgress sx={{margin: '24px auto'}} />
        </Box>
        :
      <div style={{ width: "100%", marginLeft: "160px", marginRight: "160px" }}>
      {userData?.role === ROLES.CONTRIBUTOR || userData?.role === ROLES.OPERATOR ?
      <a className="backBtn" onClick={() => navigate('/job_openings')}>
          <ArrowBackIosIcon />
          Back
      </a>
      : <></>}
        <SuccessModal open={open} handleClose={handleClose} language={lang} />
        {candidates && candidates.length > 0 ? (
          <SelectCandidateForm candidates={candidates} lang={lang} />
        ) : null}
        <h2>Professional Details</h2>
        <hr />

        <div className="ui form">
          <Dropzone
            label={language["dropCV"][lang]}
            onDataChange={onDataChange}
          />
          {/*show uploaded files*/}
          {data.files && data.files.length > 0 ? (
            <div className="field" style={{ marginTop: "24px" }}>
              <label>Files</label>
              {data.files.map((e: IDataType) => {
                return (
                  <div
                    key={e.name}
                    style={{ marginBottom: "5px", display: "flex" }}
                  >
                    <Tooltip title={`Delete File ${e.name}`}>
                      <img
                        src={trashSVG}
                        onClick={() => deleteFile(e)}
                        style={{
                          marginLeft: "0",
                          marginRight: "5px",
                          width: "16px",
                          verticalAlign: "middle",
                        }}
                      />
                    </Tooltip>
                    <span style={{ verticalAlign: "middle" }}>{e.name}</span>
                    <OpenInNewIcon
                      style={{ verticalAlign: "middle", marginLeft: "5px" }}
                      onClick={() => window.open(getFileShown(e))}
                    />
                  </div>
                );
              })}
            </div>
          ) : null}

          <div className="two fields" style={{ marginTop: "24px" }}>
            <TextField
              label={language["positionDescription"][lang]}
              onChange={handleChange("position")}
              value={data.position}
              error={!!errs.position}
            />
          </div>

          <div className="field" style={{ marginTop: "44px" }}>
            <label className=".ui.form .field>label">
              {language["seniority"][lang]}
            </label>
            <div className="inline four fields">
              <Checkbox
                onChange={handleCheckboxChange("seniority", "professional")}
                checked={data.seniority.professional}
                option={language["seniorityProfessional"][lang]}
              />
              <Checkbox
                label=""
                onChange={handleCheckboxChange("seniority", "senior")}
                checked={data.seniority.senior}
                option={language["senioritySenior"][lang]}
              />
              <Checkbox
                label=""
                onChange={handleCheckboxChange("seniority", "expert")}
                checked={data.seniority.expert}
                option={language["seniorityExpert"][lang]}
              />
            </div>
            <div className="inline four fields">
              <Checkbox
                label=""
                onChange={handleCheckboxChange("seniority", "seniorExpert")}
                checked={data.seniority.seniorExpert}
                option={language["senioritySeniorExpert"][lang]}
              />
              <CheckboxTextField
                variant="standard"
                label=""
                value={data.seniority.otherValue}
                onChange={handleCheckboxChange("seniority", "otherValue")}
                onChangeToggle={handleCheckboxChange("seniority", "other")}
                option={language["other"][lang]}
                disabled={!data.seniority.other}
                checked={data.seniority.other || false}
              />
            </div>
          </div>

          <div className="field" style={{ marginTop: "44px" }}>
            <label className=".ui.form .field>label">
              {language["location"][lang]}
            </label>
            <div className="inline four fields">
              <Checkbox
                onChange={handleCheckboxChange("location", "homeOffice0")}
                checked={data.location.homeOffice0 || false}
                option={language["locationOption1"][lang]}
              />
              <Checkbox
                label=""
                onChange={handleCheckboxChange("location", "homeOffice25")}
                checked={data.location.homeOffice25 || false}
                option={language["locationOption2"][lang]}
              />
              <Checkbox
                label=""
                onChange={handleCheckboxChange("location", "homeOffice75")}
                checked={data.location.homeOffice75 || false}
                option={language["locationOption3"][lang]}
              />
              <Checkbox
                label=""
                onChange={handleCheckboxChange("location", "homeOffice100")}
                checked={data.location.homeOffice100 || false}
                option={language["locationOption4"][lang]}
              />
            </div>
            <div className="inline four fields">
              <Checkbox
                label=""
                onChange={handleCheckboxChange("location", "nearShoring")}
                checked={data.location.nearShoring || false}
                option="Nearshoring"
              />
              <CheckboxTextField
                variant="standard"
                label=""
                value={data.location.otherValue}
                onChange={handleCheckboxChange("location", "otherValue")}
                onChangeToggle={handleCheckboxChange("location", "other")}
                option={language["other"][lang]}
                disabled={!data.location.other}
                checked={data.location.other || false}
              />
            </div>
          </div>
          <div className="field" style={{ marginTop: "44px" }}>
            <label className=".ui.form .field>label">
              {language["candidateLanguage"][lang]}
            </label>
            <div className="inline four fields">
              <RadioButtons
                label={language["languageDE"][lang]}
                options={["Fluent", "Advanced"]}
                optionLabels={[
                  language["languageFields"][lang]["Fluent"],
                  language["languageFields"][lang]["Advanced"],
                ]}
                onChange={handleChange("languageDE")}
                checked={(data && data.languageDE) || ""}
              />
              <RadioButtons
                label={language["languageEN"][lang]}
                options={["Fluent", "Advanced"]}
                optionLabels={[
                  language["languageFields"][lang]["Fluent"],
                  language["languageFields"][lang]["Advanced"],
                ]}
                onChange={handleChange("languageEN")}
                checked={(data && data.languageEN) || ""}
              />
            </div>
          </div>
          <div className="field" style={{ marginTop: "44px" }}>
            <label className=".ui.form .field>label">
              {language["start"][lang]}
            </label>
            <div className="inline four fields">
              <Checkbox
                onChange={handleCheckboxChange("startDate", "asap")}
                checked={data.startDate.asap || false}
                option={language["asap"][lang]}
              />
              <Checkbox
                label=""
                onChange={handleCheckboxChange("startDate", "m1")}
                checked={data.startDate.m1 || false}
                option={language["m1"][lang]}
              />
              <Checkbox
                label=""
                onChange={handleCheckboxChange("startDate", "m3")}
                checked={data.startDate.m3 || false}
                option={language["m3"][lang]}
              />
              <CheckboxTextField
                variant="standard"
                label=""
                value={data.startDate.otherValue}
                onChange={handleCheckboxChange("startDate", "otherValue")}
                onChangeToggle={handleCheckboxChange("startDate", "other")}
                option={language["other"][lang]}
                disabled={!data.startDate.other}
                checked={data.startDate.other || false}
              />
            </div>
          </div>
          <div className="field" style={{ marginTop: "44px" }}>
            <label className=".ui.form .field>label">
              {language["end"][lang]}
            </label>
            <div className="inline four fields">
              <Checkbox
                onChange={handleCheckboxChange("endDate", "m6")}
                checked={data.endDate.m6 || false}
                option={language["m6"][lang]}
              />
              <Checkbox
                label=""
                onChange={handleCheckboxChange("endDate", "m12")}
                checked={data.endDate.m12 || false}
                option={language["m12"][lang]}
              />
              <Checkbox
                label=""
                onChange={handleCheckboxChange("endDate", "y2")}
                checked={data.endDate.y2 || false}
                option={language["y2"][lang]}
              />
              <CheckboxTextField
                variant="standard"
                label=""
                value={data.endDate.otherValue}
                onChange={handleCheckboxChange("endDate", "otherValue")}
                onChangeToggle={handleCheckboxChange("endDate", "other")}
                option={language["other"][lang]}
                disabled={!data.endDate.other}
                checked={data.endDate.other || false}
              />
            </div>
          </div>
          <div className="field" style={{ marginTop: "44px" }}>
            <label className=".ui.form .field>label">
              {language["pensum"][lang]}
            </label>
            <div className="inline four fields">
              <Checkbox
                onChange={handleCheckboxChange("pensum", "p100")}
                checked={data.pensum.p100 || false}
                option="100%"
              />
              <Checkbox
                label=""
                onChange={handleCheckboxChange("pensum", "p80")}
                checked={data.pensum.p80 || false}
                option="80-100%"
              />
              <CheckboxTextField
                variant="standard"
                label=""
                value={data.pensum.otherValue}
                onChange={handleCheckboxChange("pensum", "otherValue")}
                onChangeToggle={handleCheckboxChange("pensum", "other")}
                option={language["other"][lang]}
                disabled={!data.pensum.other}
                checked={data.pensum.other || false}
              />
            </div>
          </div>

          <div className="two fields" style={{ marginTop: "44px" }}>
            <TextField
              label={language["chargeRate"][lang]}
              onChange={handleChange("chargeRate")}
              value={data.chargeRate || ""}
            />
          </div>


          <div className="two fields" style={{ marginTop: "44px" }}>
            <TextField
              rows={5}
              label={language["tasks"][lang]}
              onChange={handleChange("tasks")}
              value={data.tasks || ""}
            />
            <TextField
              rows={5}
              label={language["reqs"][lang]}
              onChange={handleChange("requirements")}
              value={data.requirements || ""}
            />
          </div>
          <div className="field" style={{ marginTop: "44px" }}>
            <TextField
              rows={5}
              label={language["remarks"][lang]}
              onChange={handleChange("remarks")}
              value={data.remarks || ""}
            />
          </div>
        </div>
        <h2>{language["contactDetails"][lang]}</h2>
        <hr />
        <div className="ui form">
          <div className="two fields" style={{ marginTop: "24px" }}>
            <TextField
              label={language["contact"][lang]}
              onChange={handleChange("clientName")}
              value={data.clientName}
              error={!!errs.clientName}
            />
            <TextField
              label={language["company"][lang]}
              onChange={handleChange("clientCompany")}
              value={data.clientCompany}
              error={!!errs.clientCompany}
            />
          </div>
          <div className="two fields">
            <TextField
              label={language["contactPhone"][lang]}
              onChange={handleChange("clientPhone")}
              value={data.clientPhone}
              error={!!errs.clientEmailOrPhone}
            />
            <TextField
              label={language["contactMail"][lang]}
              onChange={handleChange("clientEmail")}
              value={data.clientEmail}
              error={!!errs.clientEmailOrPhone || !!errs.clientMail}
            />
          </div>
          
          {userData?.role === ROLES.CONTRIBUTOR || userData?.role === ROLES.OPERATOR ? <>
          <div className="two fields">
            {userData?.role === ROLES.OPERATOR ?
            <DropDown
              label={language["contributor"][lang]}
              options={contributors.map((e) => {
                return { key: e.contributorId, value: e.contributorId, text: e.name };
              })}
              onChange={handleChange("contributorId")}
              value={(data && data.contributorId) || ""}
            />
            : null}
            <DropDown
              label="Status"
              options={COMMON_STATUSES.map((e) => {
                return { key: e, value: e, text: e };
              })}
              onChange={handleChange("status")}
              value={(data && data.status) || ""}
            />
          </div>
          </>: null}
        </div>
        <div
          style={{
            width: "100%",
            justifyContent: "flex-end",
            display: "flex",
            marginBottom: "30px",
          }}
        >
          {!newRequest ? (
            <BlueButton
              label={language["save"][lang]}
              onClick={() => handleUpdate(false)}
              style={{ marginRight: "0" }}
            />
          ) : (
            <BlueButton
              label={language["submit"][lang]}
              onClick={() => handleUpdate(true)}
              style={{ marginRight: "0" }}
            />
          )}
        </div>
      </div>
      }
    </div>
  );
};

export default RequestPage;
