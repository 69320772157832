import Dropzone from "../../../components/Dropzone/Dropzone";
import TextField from "../../../components/TextField/TextField";
import { Contributor, Cost, FileDTO } from "../../../interfaces/interfaces";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import trashSVG from "../../../assets/trash.svg";
import { openStorageFile} from "../../../util/fileUtil";
import { CircularProgress, Tooltip } from "@mui/material";
import DropDown from "../../../components/DropDown/DropDown";
import { ref, uploadBytes } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { storage } from "../../../firebase";
import { useState } from "react";


export const CostRow = (props: {contributors: Contributor[], cost: Cost, onChange: any}) => {
    const [uploading, setUploading] = useState(false);

    const onFileChange = async (file: any, cost: Cost) => {
      file.uploading = true;
      const fullPath = `costs/${uuidv4()}.${file.name.split('.')[1]}`;
      const costsRef = ref(storage, fullPath);
      const res = await fetch(file.base64);
      const blob = await res.blob();
      setUploading(true);
      uploadBytes(costsRef, blob).then((snapshot: any) => {
        file.fullPath = fullPath;
        delete file.base64;
        cost.file = file;
        props.onChange();
        setUploading(false);
      })
    };
  
    const handleCostChange = (field: string, cost: any, value: any) => {
      switch (field) {
        case 'year':
        case 'month':
          cost['monthYear'][field] = (value * 1);
        break;

        default:
          cost[field] = value;
      }
      props.onChange();
    };
  
    const deleteCost = (cost: any) => {
      cost.remove = true;
      props.onChange();
    };

    const deleteFile = () => {
      if (props.cost?.file) {
        // delete props.cost.file;
        props.cost.file.delete = true;
        props.onChange();
      }
    };

    const handleDescriptionChange = (field: any) => (value: any) => {
        props.cost.description = value;
        props.onChange();
    };

    const handleChange = (field: any) => (value: any) => {
    const cost = props.cost as any;
    cost[field] = value;
    props.onChange();
    };
    
  
    return (
      <tr>
        <td>
            {(props.cost.editing ?
            <div className="two fields">
              <TextField
                label="Month"
                onChange={(value: any, e: Event) =>  handleCostChange("month", props.cost, value)}
                value={props.cost?.monthYear.month}
                type="number"
              />
              <TextField
                label="Year"
                onChange={(value: any, e: Event) => handleCostChange("year", props.cost, value)}
                value={props.cost?.monthYear.year}
                type="number"
              />
            </div>
            : <>
              {new Date(`${props.cost.monthYear.year}-${props.cost.monthYear.month < 10 ? '0' + props.cost.monthYear.month : props.cost.monthYear.month}-01`).toLocaleString('en-us', {  month: 'long' })}  {props.cost.monthYear.year}
            </> )}
        </td>

        <td>
            {(props.cost.editing ?
            <>
              <TextField
                onChange={handleChange("amount")}
                value={props.cost?.amount}
                type="number"
              />
            </>
              : <>
              {props.cost.amount ? props.cost.amount + ' CHF' : '-'}
                </> 
            )}
        </td>

        <td>
            <DropDown
              options={props.contributors.map((e) => {
                return { key: e.contributorId, value: e.contributorId, text: e.name };
              })}
              onChange={handleChange("contributorId")}
              value={props?.cost.contributorId}
              readonly={!props.cost.editing}
            />
        </td>
  
        <td>
          {(props.cost.editing ?
            <div className="one fields">
              <TextField 
                  label=""
                  onChange={handleDescriptionChange("description")}
                  value={props?.cost.description}
                  rows={3}
                />
            </div>
            : <>
              {props.cost.description}
            </> )}
        </td>
  
        <td>
          {(props.cost.editing ?
            <div className="one fields">
              {uploading ?
              <CircularProgress sx={{margin: '0'}} />
              :
              <Dropzone
                label={props.cost.file && !props.cost.file.delete ? props.cost.file.name : 'Select a file'}
                onDataChange={(file: FileDTO) => onFileChange(file, props.cost)}
              />}
              {props.cost.file && !props.cost.file.delete ?
                <Tooltip title={`Delete File ${props.cost.file.name}`}>
                  <img
                    src={trashSVG}
                    onClick={() => deleteFile()}
                    style={{
                      marginLeft: "6px",
                      marginRight: "4px",
                      width: "16px",
                      cursor: "pointer",
                      marginTop: "15px",
                      verticalAlign: "middle",
                    }}
                  />
                </Tooltip>
                : null}
            </div>
            : <>
                {props.cost.file && !props.cost.file.delete ? (
                <>
                  <span style={{ verticalAlign: "middle" }}>{props.cost.file.name}</span>
                  <OpenInNewIcon
                    style={{ verticalAlign: "middle", marginLeft: "5px" }}
                    onClick={() => openStorageFile(props.cost.file as any)}
                  />
                </>
                ) : '-'}
             </>
            )}
        </td>

        <td>
          <a style={{cursor: 'pointer'}} onClick={() => handleCostChange("editing", props.cost, !props.cost.editing)}>{props.cost.editing ? 'Close': 'Edit'}</a>
        </td>
        <td>
          <Tooltip title={`Delete`}>
            <img
              src={trashSVG}
              onClick={() => deleteCost(props.cost)}
              style={{
                marginLeft: "5px",
                marginRight: "5px",
                width: "16px",
                verticalAlign: "middle",
              }}
            />
          </Tooltip>
        </td>
      </tr>
    );
  };