import React, { useState } from "react";
import { isDataView } from "util/types";
import Checkbox from "../../components/Checkbox/Checkbox";
import RadioButtons from "../../components/RadioButtons/RadioButtons";
import TextField from "../../components/TextField/TextField";
import "./LoginPage.scss";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import firebase from "../../firebase";


const LoginPage = (props: any) => {

    // const handleChange = (field: any) => (value: any) => {
    //     setData({ ...data, [field]: value });
    // };
    const [email, setEmail] = useState("");
    const [pw, setPw] = useState("");

    const login = async () => {
        try {
            await firebase.login(email, pw);
        } catch (error: any) {
            alert(error.message);
        }
    };


    return <div className="login-page">
        <div style={{ width: "100%", marginLeft: "160px", marginRight: "160px" }}>
            <div
                className="ui one column center aligned page grid"
                style={{ marginTop: "40px" }}
            >
                <form
                    className="ui large form"
                    onSubmit={(e) => e.preventDefault()}
                    style={{ width: "70%" }}
                >

                    <div className="ui stacked segment">
                        <h2 className="ui blue image header">
                            <div className="content">Login</div>
                        </h2>
                        <TextField placeholder="Email" label="Email" value={email} onChange={(e: any) => setEmail(e)} />
                        <TextField
                            label="Password"
                            onChange={(e: any) => setPw(e)}
                            placeholder="Password"
                            type="password"
                            value={pw}
                        />
                        <button
                            className="ui fluid large primary submit button"
                            type="submit"
                            onClick={login}
                        >
                            Login
                        </button>
                    </div>
                </form>

            </div>
        </div>
    </div>
}

export default LoginPage;