import React, { useEffect, useState } from "react";
import "./ContractPage.scss";
import firebase, { firestoreDB } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { BlueButton } from "../../components/Button/Button";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { Contract, STATUS } from "../../interfaces/interfaces";
import { Checkbox } from "semantic-ui-react";
import { v4 as uuidv4 } from "uuid";
import DropDown from "../../components/DropDown/DropDown";
import { CONTRACTS_FILTER_KEY, CONTRACT_STATUSES } from "../../constants";
import { NavTabs } from "../../components/NavTabs/NavTabs";
import { Box, Chip, CircularProgress } from "@mui/material";

const formatDate = (d: string) => {
  return new Date(d).toLocaleDateString();
};

const ContractPage = (props: any) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(Boolean);
  const [contracts, setContracts] = useState(Array<Contract>);
  const savedFilters = localStorage.getItem(CONTRACTS_FILTER_KEY);
  const [statusFilter, setStatusFilter] = useState(savedFilters ? JSON.parse(savedFilters) : [STATUS.ACTIVE, STATUS.PROSPECT]);

  const fetchContracts = async (filters: any) => {
    setLoading(true);
    localStorage.setItem(CONTRACTS_FILTER_KEY, JSON.stringify(filters));
    setContracts(await firebase.getContracts(filters) as Contract[]);
    setLoading(false);
  };

  const filter = (data: any) => {
    setStatusFilter(data);
    fetchContracts(data);
  };

  useEffect(() => {
    fetchContracts(statusFilter);
  }, []);

  if (!props.isLogin) {
    navigate('/login');
  }

  if (props.userData?.role !== 'operator') {
    navigate('/reports');
  }

  return (
    <div className="table-default">
      <NavTabs userData={props.userData} />
      
      <div style={{ width: "100%", display: 'flex' }}>
        <BlueButton
          label="New Contract"
          onClick={() => navigate("/contracts/" + uuidv4())}
          style={{ marginBottom: "20px" }}
        />

        <div
          style={{marginLeft: 'auto', marginTop: '-14px'}}>
          <DropDown
            label="Status"
            options={CONTRACT_STATUSES.map((e) => {
              return { key: e, value: e, text: e };
            })}
            onChange={filter}
            value={statusFilter}
            multiple={true}
          />
        </div>
      </div>

      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Company Name</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Status</th>
          </tr>
        </thead>

        <tbody>
          {contracts.map((contract, i) => <ContractRow key={`row_${i}`} contract={contract} />)}
        </tbody>
      </table>
      {loading ?
      <Box sx={{ display: 'flex' }}>
        <CircularProgress sx={{margin: '24px auto'}} />
      </Box>
      : <></>}
    </div>
  );
};

const ContractRow = (props: {contract: Contract}) => {
  const navigate = useNavigate();

  return (
    <tr>
      <td>
        <a onClick={() => navigate('/contracts/'+ props.contract.contractId)}>
          {props.contract.name}
        </a>
      </td>
      <td>{props.contract.companyName ?? '-'}</td>
      <td>{props.contract.startDate ? formatDate(props.contract.startDate) : '-'}</td>
      <td>{props.contract.endDate ? formatDate(props.contract.endDate) : '-'}</td>
      <td>
        {props.contract.status ?
          <Chip className={`status_chip status_chip_${props.contract.status.toLowerCase()}`} label={props.contract.status} color="success" variant="outlined" />
        : '-'}
      </td>
    </tr>
  );
};

export default ContractPage;
