import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 230,
  bgcolor: "background.paper",
  border: "2px solid white",
  boxShadow: 24,
  borderRadius: 4,
  p: 4,
};

const DeleteModal = (props: any) => {
  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Confirm Deletion
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {props.toDeleteCand ? props.toDeleteCand.id : null}
          {props.toDeleteWhitelist ? props.toDeleteWhitelist.companyName : null}
          {props.toDeleteReq ? props.toDeleteReq.id : null}
        </Typography>
        <div style={{ marginTop: "40px" }} />
        <Button
          variant="outlined"
          onClick={props.handleClose}
          style={{
            position: "absolute",
            // right: "0",
            marginBottom: "20px",
            marginRight: "20px",
          }}
        >
          Close
        </Button>
        <Button
          variant="outlined"
          onClick={props.confirmDelete}
          style={{
            position: "absolute",
            right: "0",
            // marginLeft: "auto",
            marginBottom: "20px",
            marginRight: "20px",
          }}
        >
          Confirm
        </Button>
      </Box>
    </Modal>
  );
};

export default DeleteModal;
