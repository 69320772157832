import React from "react";
import { Dropdown } from "semantic-ui-react";
import { Tooltip } from "@mui/material";
import deleteSVG from "../../assets/delete.svg";

const DropDown = (props: any) => {
  const setChange = (e: any, { value }: any) => {
    props.onChange(value);
  };

  const getLabel = () => {
    const option = props.options.find((option: any) => option.key === props.value);
    if (option) return option.text;
    return '-';
  };

  const deleteSelection = () => {
    props.onChange(null);
  }

  return (
    <div className="field" style={{minWidth: '100px', position: 'relative'}}>
      <Tooltip title={props.tooltip ? props.tooltip : ""} placement="left">
        {props.error ? (
          <label style={{ color: "red" }}>{props.label}</label>
        ) : (
          <label>{props.label}</label>
        )}
      </Tooltip>
      {!props.readonly ? 
      <>
        <Dropdown
          placeholder={props.placeholder}
          fluid
          search
          selection
          value={props.value}
          options={props.options}
          onChange={(e, val) => setChange(e, val)}
          multiple={props.multiple}
          {...props.rest}
        />
        {props.removable && props.value ? <>
          <img
            src={deleteSVG}
            onClick={() => deleteSelection()}
            style={{
              verticalAlign: "middle",
              position: 'absolute',
              top: '38px',
              right: '43px',
              cursor: 'pointer',
              marginLeft: 'auto',
              width: '12px'
            }}
          />
        </> : null}
      </>
      : <>{getLabel()}</>}
    </div>
  );
};

export default DropDown;
