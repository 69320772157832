import React, { useEffect, useState } from "react";
import "./WhitelistPage.scss";
import firebase, { firestoreDB } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { BlueButton } from "../../components/Button/Button";
import { collection, onSnapshot, query } from "firebase/firestore";
import { Whitelist } from "../../interfaces/interfaces";
import { v4 as uuidv4 } from "uuid";
import { NavTabs } from "../../components/NavTabs/NavTabs";
import { Tooltip } from "@mui/material";
import trashSVG from "../../assets/trash.svg";
import DeleteModal from "../../components/DeleteModal/DeleteModal";
import { ROLES } from "../../constants";


const WhitelistPage = (props: any) => {
  const navigate = useNavigate();

  const [whitelist, setWhitelist] = useState(Array<Whitelist>);
  const [toDelete, setToDelete] = React.useState<Whitelist | null>();
  const [openDelete, setOpenDelete] = useState(false);


  const fetchWhitelist = () => {
    const unsubscribe = onSnapshot(query(collection(firestoreDB, "whitelist") ), (querySnapshot: any) => {
      const res: Array<object> = [];
      
      querySnapshot.forEach((doc: any) => {
        res.push(doc.data());
      });
      const t: Array<Whitelist> = res as Array<Whitelist>;
      setWhitelist(t);
    });
  };

  const deleteWhitelist = (whitelist: Whitelist) => {
    firebase.deleteWhitelist(whitelist.whitelistId);
  };

  const handleOpenDelete = (item: Whitelist) => {
    setToDelete(item);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setToDelete(null);
    setOpenDelete(false);
  };

  const confirmDelete = () => {
    if (toDelete) {
      deleteWhitelist(toDelete);
    }
    handleCloseDelete();
  };

  useEffect(() => {
    fetchWhitelist();
  }, []);

  return (
    <>
      <DeleteModal
        open={openDelete}
        handleClose={handleCloseDelete}
        confirmDelete={confirmDelete}
        toDeleteWhitelist={toDelete}
      />
      <div className="table-default">
        <NavTabs userData={props.userData} />
        <div style={{ width: "100%", display: 'flex' }}>
        {props.userData?.role === ROLES.OPERATOR ?
          <BlueButton
            label="New Entry"
            onClick={() => navigate("/whitelist/" + uuidv4())}
            style={{ marginBottom: "20px" }}
          />
        : null}
        </div>

        <table>
          <thead>
            <tr>
              <th>Company Name</th>
              <th>Category</th>
              <th>Remark</th>
              {props.userData?.role === ROLES.OPERATOR ?
              <th></th>
              : null}
            </tr>
          </thead>

          <tbody>
            {whitelist.map((item, i) => (
              <tr key={`item_${i}`}>
                <td>
                  {props.userData?.role === ROLES.OPERATOR ?
                    <a onClick={() => navigate('/whitelist/'+ item.whitelistId)}>
                      {item.companyName}
                    </a>
                  : item.companyName}
                </td>
                <td>{item.category ?? '-'}</td>
                <td>{item.remark ? item.remark : '-'}</td>
                {props.userData?.role === ROLES.OPERATOR ?
                  <td>
                  <Tooltip title={"Delete Candidate"}>
                      <img
                        src={trashSVG}
                        onClick={() => handleOpenDelete(item)}
                        style={{ marginLeft: "10px", marginRight: "0" }}
                      />
                  </Tooltip>
                  </td>
                : null}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default WhitelistPage;
