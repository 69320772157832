import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';

import React from "react";

const Datepicker = (props: any) => {
  const val = props.value ? new Date(props.value) : null;

  const set = (e: any, data: any) => {
    props.onChange(data.value);
  };

  return (
      <div className="field" style={props.fieldStyle}>
        {props.error ? (
            <label style={{color: 'red'}}>{props.label}</label>
        ) : (
            <label>{props.label}</label>
        )}
        <SemanticDatepicker className='datepicker' onChange={set} value={val} />
      </div>
  );
}

export default Datepicker;