import { STATUS } from "./interfaces/interfaces";

export const MARITALSTATUS = [
  "Married",
  "Single",
  "Divorced",
  "Widowed",
  "Registered Partnership",
];

export const WORK_PERMITS = ["-", "B", "C", "L", "G"];
export const CONTRACT_STATUSES = [
  STATUS.ACTIVE,
  STATUS.ARCHIVE,
  STATUS.INACTIVE,
  STATUS.PROSPECT
];

export const COMMON_STATUSES = [
  STATUS.ACTIVE,
  STATUS.ARCHIVE,
  STATUS.INACTIVE,
];

export const GENDER = [
  "Male",
  "Female"
];

export enum ROLES {
  OPERATOR = 'operator',
  CONTRIBUTOR = 'contributor'
};

export enum CONTRIBUTOR_TYPE {
  LEAD_INTERMEDIARY = 'Lead intermediary',
  AGENT = 'Agent',
  AGENCY = 'Agency',
  PARTNER = 'Partner (internal)'
};

export const CONTRIBUTOR_TYPES = [
  CONTRIBUTOR_TYPE.LEAD_INTERMEDIARY,
  CONTRIBUTOR_TYPE.AGENT,
  CONTRIBUTOR_TYPE.AGENCY,
  CONTRIBUTOR_TYPE.PARTNER
];

export const CONTRACTS_FILTER_KEY = 'contracts';
export const CANDIDATES_FILTER_KEY = 'candidates';
export const JOB_OPENINGS_FILTER_KEY = 'job_openings';
export const CONTRIBUTORS_FILTER_KEY = 'contributors';

export const REPORTS_MONTHS = [
  {value: 1, label: "January"},
  {value: 2, label: "February"},
  {value: 3, label: "March"},
  {value: 4, label: "April"},
  {value: 5, label: "May"},
  {value: 6, label: "June"},
  {value: 7, label: "July"},
  {value: 8, label: "August"},
  {value: 9, label: "September"},
  {value: 10, label: "October"},
  {value: 11, label: "November"},
  {value: 12, label: "December"}
];

export const REPORTS_YEARS = [
  new Date().getFullYear() - 1,
  new Date().getFullYear(),
  new Date().getFullYear() + 1
]